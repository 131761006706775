import { AccountType } from '@common/enums';


export function experienceDefaultUrl(type: AccountType): string[] {
  switch (type) {
    case AccountType.FieldStaff:
    case AccountType.OutsourcedStaff:
    case AccountType.Candidate:
      return ['/tempstaff'];
    case AccountType.Contact:
      return ['/contact'];
    default:
      return ['/'];
  }
}
