import { IdentificationAction } from '../enums';


export const IdentificationActions = [
  { name: 'Check In', value: IdentificationAction.CheckIn },
  { name: 'Check Out', value: IdentificationAction.CheckOut },
  { name: 'Manage', value: IdentificationAction.Manage },
  { name: 'Unscheduled', value: IdentificationAction.Unscheduled },
  { name: 'Not Found', value: IdentificationAction.NotFound },
  { name: 'Need help', value: IdentificationAction.NeedHelp },
];
