import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FsBadgeModule } from '@firestitch/badge';
import { FsDateModule } from '@firestitch/date';
import { FsDialogModule } from '@firestitch/dialog';
import { FsFilterModule } from '@firestitch/filter';
import { FsListModule } from '@firestitch/list';
import { FsNavModule } from '@firestitch/nav';
import { FsSidenavModule } from '@firestitch/sidenav';

import { LogoModule } from '@common/modules/logo';

import { WorkspaceCreateDialogModule } from '@libs/dialogs/workspace-create';
import { ProjectCreateModule } from '@libs/project-create';

import { CreateModule } from '@app/environment/modules/create';

import {
  EnvironmentMenuComponent,
  EnvironmentSwitchComponent,
  HeaderComponent,
  NavbarComponent,
  SideNavComponent,
} from './components';


@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    ScrollingModule,

    MatDialogModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,

    FsSidenavModule,
    FsDialogModule,
    FsBadgeModule,
    FsNavModule,
    FsFilterModule,
    FsListModule,
    FsDateModule,

    FlexLayoutModule,

    WorkspaceCreateDialogModule,
    LogoModule,
    ProjectCreateModule,
    CreateModule,
  ],
  declarations: [
    SideNavComponent,
    EnvironmentSwitchComponent,
    EnvironmentMenuComponent,
    HeaderComponent,
    NavbarComponent,
  ],
  exports: [
    SideNavComponent,
    HeaderComponent,
  ],
})
export class SideNavModule { }
