import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree,
} from '@angular/router';

import { SigninService } from '@common/services';


@Injectable({
  providedIn: 'root',
})
export class LoggedOutGuard implements CanActivate {

  constructor(
    private _signinService: SigninService,
    private _router: Router,
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (this._signinService.signedIn()) {
      return this._router.createUrlTree(['/']);
    }

    return true;
  }

}
