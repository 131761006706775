import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class AccountAvailabilityData {

  constructor(
    private _api: FsApi,
  ) { }

  public gets(accountId, data = {}, config: RequestConfig = {}): Observable<any> {
    return this._api.get(`accounts/${accountId}/availabilities`, data, {
      key: 'accountAvailabilities',
      ...config,
    });
  }

  public post(accountId, data = {}, config: RequestConfig = {}): Observable<any> {
    return this._api.post(`accounts/${accountId}/availabilities`, data, {
      key: 'accountAvailabilities',
      ...config,
    });
  }

}
