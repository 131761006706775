import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree,
} from '@angular/router';

import { FsMessage, MessageMode } from '@firestitch/message';

import { SessionService, AuthService } from '@app/core/services';


@Injectable({
  providedIn: 'root',
})
export class Force2faGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _sessionService: SessionService,
    private _message: FsMessage,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (this._sessionService.force2fa) {
      this._message.info('Please enable two factor authentication to protect your account.',
        { mode: MessageMode.Toast });

      return this._router.createUrlTree(['/twofactor']);
    }

    return true;
  }

}
