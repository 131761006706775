import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';

import { Type } from '@app/common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class TypeData {

  constructor(private _fsApi: FsApi) { }

  public create(data: Type = { id: null }): Type {
    data.class = data.class || 'task';
    data.icon = data.icon || null;

    return data;
  }

  public get(typeId: number, query: any = {}): Observable<any> {
    return this._fsApi.get(`types/${typeId}`, query, { key: 'type' });
  }

  public gets(query = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', 'types', query, { key: 'types', ...config });
  }

  public put(type, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.put(`types/${type.id}`, type, { key: 'type', ...config });
  }

  public post(type): Observable<any> {
    return this._fsApi.post('types', type, { key: 'type' });
  }

  public delete(type): Observable<any> {
    return this._fsApi.delete(`types/${type.id}`, type, { key: 'type' });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }

    return this.post(data);
  }

  public order(data): Observable<any> {
    return this._fsApi.put('types/order', data, { key: null });
  }

}
