import { Injectable, Injector } from '@angular/core';
import { DefaultUrlSerializer, Router } from '@angular/router';

import { FsCookie } from '@firestitch/cookie';
import { parse } from '@firestitch/date';
import { FsMessage } from '@firestitch/message';
import { FsStore } from '@firestitch/store';

import { Observable, of } from 'rxjs';


import { isAfter } from 'date-fns';

import { Account } from '../interfaces';

import { SessionService } from './session.service';


@Injectable({
  providedIn: 'root',
})
export class SigninService {

  private readonly _signinRedirect = 'signinRedirect';

  constructor(
    protected _injector: Injector,
    protected _cookie: FsCookie,
    protected _router: Router,
    protected _store: FsStore,
    protected _message: FsMessage,
    protected _sessionService: SessionService,
  ) { }

  public signedIn(): boolean {
    const expiry = parse(this._cookie.get('Token-Expiry'));

    return expiry && isAfter(expiry, new Date()) && !!this._sessionService.account();
  }

  public processSignin(
    response: { account: Account },
    redirect?: string,
  ): Observable<any> {
    this.setSession(response);
    this._processRedirect(redirect);
    this._message.success(`Welcome ${response.account.firstName}`);

    return of(response);
  }

  public setSession(response: any): void {
    this._sessionService.set(response);
  }

  protected _processRedirect(redirect: string = ''): void {
    redirect = redirect ? redirect : this._store.get(this._signinRedirect);
    this._store.remove(this._signinRedirect);

    if (redirect) {
      this._router.navigateByUrl(new DefaultUrlSerializer().parse(redirect));
    } else {
      this._router.navigateByUrl('/');
    }
  }

}
