import { Component, Inject, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EnvironmentCreate } from '../../interfaces';


@Component({
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateComponent implements OnInit {

  public environmentCreate: EnvironmentCreate = { members: []};
  public step = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _dialogRef: MatDialogRef<CreateComponent>,
  ) { }

  public ngOnInit(): void {
    this._dialogRef
      .addPanelClass('full-width-dialog')
      .addPanelClass('environment-create');
  }

  public stepNext(): void {
    this.step ++;
  }

  public close(): void {
    this._dialogRef.close();
  }

}
