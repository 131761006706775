import { FsCookie } from '@firestitch/cookie';
import { FsMessage } from '@firestitch/message';
import { FsTransferHandler, Request } from '@firestitch/transfer';


export class TransferHandler extends FsTransferHandler {

  constructor(
    private _message: FsMessage,
    private _cookie: FsCookie,
  ) {
    super();
  }

  public begin(request: Request): Request {
    const xsrf = this._cookie.get('Token-XSRF');
    if (xsrf) {
      request.params['tokenXsrf'] = xsrf;
    }

    this._message.info('Starting download...');

    return request.clone({ path: `api/${request.path}` });
  }

  public error(data: any, raw: string): void {
    const message = data && data.message ? data.message : 'There was a problem with the download';
    this._message.error(message);
  }

}
