import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { map } from 'rxjs/operators';

import { AccountData } from '@common/data';
import { EnvironmentData, SessionService } from 'app/core';

import { SidenavService } from '@libs/layouts/side-nav/services';


@Component({
  templateUrl: './environment-switch.component.html',
  styleUrls: ['./environment-switch.component.scss'],
})
export class EnvironmentSwitchComponent implements OnInit {

  public environments: any[] = [];
  public projectLimit = 5;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<EnvironmentSwitchComponent>,
    private _environmentData: EnvironmentData,
    private _accountData: AccountData,
    private _sessionService: SessionService,
    private _router: Router,
    private _sidenavService: SidenavService,
  ) { }

  public ngOnInit(): void {
    this._accountData.getEnvironments(this._sessionService.accountId(), {
      avatars: true,
      projects: true,
      projectAvatars: true,
    })
      .pipe(
        map((environments) => {
          return environments
            .map((environment) => {
              if (environment.projects.length > this.projectLimit) {
                environment.projects = environment.projects.slice(0, this.projectLimit);
              }

              return environment;
            });
        }),
      )
      .subscribe((response) => (this.environments = response));
  }

  public switch(environment): void {
    this._environmentData
      .switch(this._sessionService.accountId(), environment.id)
      .subscribe((environment_) => {
        this._sidenavService.hide();
        this._sessionService.environment(environment_);
        this._dialogRef.close(environment_);
        this._router.navigate(['blank']).then(() => {
          this._router.navigateByUrl('/');
        });
      });
  }

  public close(): void {
    this._dialogRef.close();
  }
}
