import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';

import { INote } from '@app/common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class NoteData {

  constructor(
    private _fsApi: FsApi,
  ) { }

  public create(data: INote = { id: null }): INote {
    data.noteRelations = data.noteRelations || [];

    return data;
  }

  public get(id: number, query: any = {}): Observable<any> {
    return this._fsApi.get(`notes/${id}`, query, { key: 'note' });
  }

  public gets(query = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', 'notes', query, { key: 'notes', ...config });
  }

  public put(data, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.put(`notes/${data.id}`, data, { key: 'note', ...config });
  }

  public post(data): Observable<any> {
    return this._fsApi.post('notes', data, { key: 'note' });
  }

  public delete(data): Observable<any> {
    return this._fsApi.delete(`notes/${data.id}`, data, { key: 'note' });
  }

  public relations(data: any = {}): Observable<any> {
    return this._fsApi.get('notes/relations', data, { key: 'relations' });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }

    return this.post(data);
  }

}
