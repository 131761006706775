import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { FsAclModule } from '@firestitch/acl';
import { FsAddressModule, GOOGLE_MAP_KEY } from '@firestitch/address';
import {
  FS_API_REQUEST_INTERCEPTOR,
  FS_API_RESPONSE_HANDLER,
  FsApi,
  FsApiModule,
} from '@firestitch/api';
import { FS_APP_ACL_CONFIG, FsAppAclModule } from '@firestitch/app-acl';
import { FS_APP_MESSAGE_CONFIG } from '@firestitch/app-message';
import { FS_ATTRIBUTE_CONFIG, FsAttributeModule } from '@firestitch/attribute';
import { FsAutocompleteChipsModule } from '@firestitch/autocomplete-chips';
import { FsBodyModule } from '@firestitch/body';
import {
  BuildConfig,
  FS_BUILD_CONFIG, FsBuildModule, FsBuildService, UpdateAction,
} from '@firestitch/build';
import { FS_CONTENT_CONFIG } from '@firestitch/content';
import { FS_CONTENT_WIDGET_CONFIG } from '@firestitch/content-widget';
import { FsCookie } from '@firestitch/cookie';
import { FsCoreModule } from '@firestitch/core';
import { FsDatePickerModule, WeekDay } from '@firestitch/datepicker';
import { FsDialogModule } from '@firestitch/dialog';
import { DRAWER_DEFAULT_CONFIG } from '@firestitch/drawer';
import { FsFieldEditorModule } from '@firestitch/field-editor';
import { FsFileModule } from '@firestitch/file';
import { FsFilterModule } from '@firestitch/filter';
import { FsFormModule } from '@firestitch/form';
import { FsHtmlEditorModule } from '@firestitch/html-editor';
import { FsListModule } from '@firestitch/list';
import { FS_MAP_GOOGLE_MAP_KEY } from '@firestitch/map';
import { FsErrorMessage, FsMessage, FsMessageModule } from '@firestitch/message';
import { FsNavModule } from '@firestitch/nav';
import { FsPasswordModule } from '@firestitch/password';
import { FS_PHONE_CONFIG, FsPhoneModule } from '@firestitch/phone';
import { FsPopoverModule } from '@firestitch/popover';
import { FsProgressModule } from '@firestitch/progress';
import { FsPrompt, FsPromptModule } from '@firestitch/prompt';
import { FsRatingModule } from '@firestitch/rating';
import { FsScrollModule } from '@firestitch/scroll';
import { FsScrollbarModule } from '@firestitch/scrollbar';
import { FsSelectionModule } from '@firestitch/selection';
import { FsSigninModule, SigninConfig } from '@firestitch/signin';
import { FsSigninSecurityModule } from '@firestitch/signin-security';
import { FsStore, FsStoreModule } from '@firestitch/store';
import { FsTabsModule } from '@firestitch/tabs';
import { FsTextEditorModule } from '@firestitch/text-editor';
import { FS_TRANSFER_HANDLER, FsTransferService } from '@firestitch/transfer';
import { FsUploadModule } from '@firestitch/upload';

import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { CmsData } from '@common/data';
import { ApiHandler, TransferHandler } from '@common/handlers';
import { contentWidgetConfigFactory, throwIfAlreadyLoaded } from '@common/helpers';
import { AppService, SignoutService } from '@common/services';
import { environment } from '@env/environment';
import { DragulaModule } from 'ng2-dragula';

import { DrawerModule } from '@libs/drawers/drawer';
import { ShiftAssignmentDrawerModule } from '@libs/drawers/shift-assignment';

import { AclRoleLevels } from '@app/common';
import {
  AclRoleData,
  MessageData, MessageQueueData,
  MessageTemplateData, MessageWebhookData, SessionService,
} from '@app/core';
import { SigninService } from '@app/core/services/signin.service';

import {
  AttributeData,
} from './';
import { ListDefaultConfig } from './consts';
import { contentConfigFactory } from './helpers';
import { appMessageConfig } from './helpers/app-message-config';
import { attributeConfigFactory } from './helpers/attribute-config-factory';
import { jwtOptionsFactory } from './helpers/jwt-options-factory';
import { ApiInterceptorFactory } from './interceptors';
import { AccountService, AttributeService } from './services';


@NgModule({
  imports: [
    FsApiModule.forRoot({ maxFileConnections: 5 }),
    FsStoreModule,
    FsPasswordModule.forRoot(),
    FsMessageModule.forRoot(),
    FsSelectionModule,
    FsTabsModule.forRoot(),
    FsNavModule.forRoot({ watchBrowserBackButton: false }),
    FsCoreModule,
    FsListModule.forRoot(ListDefaultConfig),
    FsPromptModule.forRoot(),
    FsFormModule.forRoot(),
    FsFieldEditorModule.forRoot(),
    FsScrollModule.forRoot(),
    FsBodyModule.forRoot(),
    FsTextEditorModule.forRoot(),
    FsFilterModule.forRoot({
      case: 'camel',
      queryParam: true,
      chips: true,
      button: {
        label: '',
      },
    }),
    FsUploadModule.forRoot(),
    FsFileModule.forRoot(),
    FsAclModule,
    FsTabsModule.forRoot(),
    FsAutocompleteChipsModule.forRoot(),
    FsProgressModule.forRoot({ barColor: '#F3726C' }),
    FsAttributeModule.forRoot(),
    FsScrollbarModule.forRoot(),
    FsSigninSecurityModule.forRoot({ case: 'camel' }),
    FsRatingModule.forRoot(),
    FsDatePickerModule.forRoot({
      weekStartsOn: WeekDay.Monday,
    }),
    FsPhoneModule.forRoot({
      isoCountryCode: 'CA',
    }),
    FsDialogModule.forRoot(),
    FsAppAclModule,
    FsPopoverModule,
    FsHtmlEditorModule.forRoot({
      activationKey: '2J1B10dD7F6F5A3F3I3cWHNGGDTCWHId1Eb1Oc1Yh1b2Ld1POkE3D3F3C9A4E5A3G3B2G2==',
    }),
    DragulaModule.forRoot(),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [FsStore],
      },
    }),
    FsSigninModule.forRoot({
      factory: (appService: AppService, signinService: SigninService): SigninConfig => {
        return {
          trustedDeviceExpiryDays: appService.settings.trustedDeviceExpiryDays,
          processSignin: (response, redirect) => signinService
            .processSignin(response, redirect),
          signinMeta: () => ({
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }),
        };
      },
      deps: [AppService, SigninService],
    }),
    FsAddressModule.forRoot(),
    DrawerModule,
    ShiftAssignmentDrawerModule,
  ],
  providers: [
    NgForm,
    FsTransferService,
    FsApi,
    FsStore,
    SigninService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appService: AppService, buildService: FsBuildService) => {
        return () => appService.load()
          .pipe(
            tap(() => {
              if (environment.configuration !== 'local') {
                buildService.listen();
              }
            }),
          )
          .toPromise();
      },
      multi: true,
      deps: [AppService, FsBuildService],
    },
    {
      provide: FS_PHONE_CONFIG,
      useValue: { country: 'CA' },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { maxWidth: '95vw', hasBackdrop: true, autoFocus: true },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'auto' },
    },
    {
      provide: FS_TRANSFER_HANDLER,
      useClass: TransferHandler,
      deps: [FsMessage, FsCookie],
    },
    {
      provide: FS_API_REQUEST_INTERCEPTOR,
      useFactory: ApiInterceptorFactory,
      deps: [FsCookie],
    },
    {
      provide: FS_API_RESPONSE_HANDLER,
      useClass: ApiHandler,
      deps: [FsMessage, Router, FsErrorMessage, SignoutService],
    },
    {
      provide: FS_MAP_GOOGLE_MAP_KEY,
      useFactory: () => 'AIzaSyB8gMS9T3HX5jisNBaIoll40Dbh7-WO-Mc',
    },
    {
      provide: GOOGLE_MAP_KEY,
      useFactory: (appService: AppService) => {
        return appService.settings.googleMapKey;
      },
      deps: [AppService],
    },
    {
      provide: FS_ATTRIBUTE_CONFIG,
      useFactory: attributeConfigFactory,
      deps: [
        AttributeData,
        AttributeService,
        FsPrompt,
        FsMessage,
      ],
    },
    {
      provide: DRAWER_DEFAULT_CONFIG,
      useValue: {
        width: {
          side: {
            min: 300,
          },
        },
      },
    },
    {
      provide: FS_APP_ACL_CONFIG,
      useFactory: (aclRoleData: AclRoleData) => {
        return {
          permissions: aclRoleData.permissions(),
          levels: of(AclRoleLevels),
        };
      },
      deps: [AclRoleData],
    },
    {
      provide: FS_APP_MESSAGE_CONFIG,
      useFactory: appMessageConfig,
      deps: [
        MessageData, MessageQueueData, MessageTemplateData,
        MessageWebhookData, SessionService, AccountService,
      ],
    },
    {
      provide: FS_CONTENT_CONFIG,
      useFactory: contentConfigFactory,
      deps: [CmsData],
    },
    {
      provide: FS_CONTENT_WIDGET_CONFIG,
      useFactory: contentWidgetConfigFactory,
      deps: [CmsData],
    },
    {
      provide: FS_BUILD_CONFIG,
      useFactory: (): BuildConfig => {
        return {
          updateAction: environment.production ?
            UpdateAction.NavigationUpdate : UpdateAction.PromptUpdate,
        };
      },
    },
  ],
  exports: [
    FsProgressModule,
    FsBuildModule,
  ],
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

}
