import { ShiftState } from '@common/enums';


export const ShiftStates = [
  { name: 'Unassigned', value: ShiftState.Unassigned, color: '#d72112', icon: null },
  { name: 'Tentative', value: ShiftState.Tentative, color: '#f7941d', icon: 'help_outline' },
  { name: 'Confirmed', value: ShiftState.Confirmed, color: '#7cb342', icon: 'done' },
  { name: 'Cancelled', value: ShiftState.Cancelled, color: '#98a6d0', icon: 'not_interested' },
  { name: 'Declined', value: ShiftState.Declined, color: '#dc1f00', icon: 'thumb_down' },
  { name: 'Deleted', value: ShiftState.Deleted, color: null, icon: null },
];
