import { FsContentWidgetConfig } from '@firestitch/content-widget';

import { CmsData } from '../data';


export function contentWidgetConfigFactory(contentCms: CmsData): FsContentWidgetConfig {
  return {
    fetchContentWidget: (tag: string) => {
      return contentCms.contentWidgetcontent(tag);
    },
  };
}
