import { ObjectClass } from '../enums';

import { IObjectClass } from './../interfaces/object-class';

export const ObjectClasses: IObjectClass[] = [
  {
    name: 'Account',
    value: ObjectClass.Account,
    pluralName: 'Account',
  },
  {
    name: 'Field Staff',
    value: ObjectClass.FieldStaff,
    pluralName: 'Field Staff',
  },
  {
    name: 'Office Staff',
    value: ObjectClass.OfficeStaff,
    pluralName: 'Office Staff',
  },
  {
    name: 'Candidate',
    value: ObjectClass.Candidate,
    pluralName: 'Candidates',
  },
  {
    name: 'Outsourced Staff',
    value: ObjectClass.OutsourcedStaff,
    pluralName: 'Outsourced Staff',
  },
  {
    name: 'Contact',
    value: ObjectClass.Contact,
    pluralName: 'Contacts',
  },
  {
    name: 'Client',
    value: ObjectClass.Client,
    pluralName: 'Clients',
  },
  {
    name: 'Client Location',
    value: ObjectClass.ClientLocation,
    pluralName: 'Client Locations',
  },
  {
    name: 'Client Account',
    value: ObjectClass.ClientAccount,
    pluralName: 'Client Accounts',
  },
  {
    name: 'Office',
    value: ObjectClass.Office,
    pluralName: 'Offices',
  },
  {
    name: 'Shift',
    value: ObjectClass.Shift,
    pluralName: 'Shifts',
  },
  {
    name: 'Shift Actual',
    value: ObjectClass.ShiftActual,
    pluralName: 'Shift Actuals',
  },
  {
    name: 'Week',
    value: ObjectClass.Week,
    pluralName: 'Weeks',
  },
  {
    name: 'Adjustment',
    value: ObjectClass.Adjustment,
    pluralName: 'Adjustments',
  },
  {
    name: 'Payer',
    value: ObjectClass.Payer,
    pluralName: 'Payers',
  },
  {
    name: 'Preference',
    value: ObjectClass.Preference,
    pluralName: 'Preferences',
  },
  {
    name: 'Employee Certification',
    value: ObjectClass.EmployeeCertification,
    pluralName: 'Employee Certification',
  },
  {
    name: 'Address',
    value: ObjectClass.Address,
    icon: 'person',
    pluralName: 'Addresses',
  },
  {
    name: 'File',
    value: ObjectClass.File,
    pluralName: 'Files',
  },
  {
    name: 'Tag',
    value: ObjectClass.Tag, icon:
      'person',
    pluralName: 'Tags',
  },
  {
    name: 'Type',
    value: ObjectClass.Type,
    icon: 'person',
    pluralName: 'Types',
  },
  {
    name: 'Status',
    value: ObjectClass.Status,
    icon: 'person',
    pluralName: 'Statuses',
  },

];

