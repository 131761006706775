import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountData } from '@common/data';

import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {

  constructor(
    private _sessionService: SessionService,
    private _injector: Injector,
  ) { }

  public accountEmail(): Observable<string> {
    return this._injector.get(AccountData)
      .get(this._sessionService.accountId())
      .pipe(
        map((account) => (account.email)),
      );
  }

}
