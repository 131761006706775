import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FsMessage } from '@firestitch/message';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ShiftData } from '@common/data';

import { IShift } from '@app/common/interfaces';
import { EventEmitterService, MentionService, ObjectData } from '@app/core';


@Component({
  templateUrl: './shift-assignment.component.html',
  styleUrls: ['./shift-assignment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftAssignmentComponent implements OnInit, OnDestroy {

  public shift: IShift = null;

  private _destroy$ = new Subject();

  constructor(
    // public drawer: DrawerRef<ShiftAssignmentComponent>,
    private _dialogRef: MatDialogRef<ShiftAssignmentComponent>,
    private _shiftData: ShiftData,
    private _message: FsMessage,
    private _objectData: ObjectData,
    private _cdRef: ChangeDetectorRef,
    private _eventService: EventEmitterService,
    private _mentionService: MentionService,
    @Inject(MAT_DIALOG_DATA) public data: {
      shift: IShift;
    },
    // @Inject(DRAWER_DATA) public data: any,
  ) { }

  public ngOnInit(): void {
    this.shift = this.data.shift;
    // this.drawer.dataChanged$.pipe(
    //   takeUntil(this._destroy$),
    // ).subscribe((data: any) => {
    //   console.log(data);
    //   this.shift = this._shiftData.create({ ...data.shift });
    //   this._cdRef.markForCheck();
    // });
  }

  public close(data: any = null): void {
    this._dialogRef.close(data);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public save = () => {
    return this._shiftData.save(this.shift)
      .pipe(
        tap(() => {
          this._message.success('Saved Changes');
        }),
      );
  };

}
