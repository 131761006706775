import { Injectable } from '@angular/core';

import { FsApi } from '@firestitch/api';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MentionData {

  constructor(private _api: FsApi) { }

  public accountsPost(objectId: number, data: { mentions: any[] }): Observable<any> {
    return this._api.request('POST', `mentions/${objectId}/accounts`, data);
  }
  public objectsPost(objectId: number, data: { mentions: any[] }): Observable<any> {
    return this._api.request('POST', `mentions/${objectId}/objects`, data);
  }

}
