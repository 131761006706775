<div class="navbar">
  <a class="back" fsNavBack><mat-icon class="back-icon">keyboard_arrow_left</mat-icon></a>
  <div class="left-actions" fsNavActions="left"></div>
  <div class="content">
    <div fsNavComponent="image" type="image"></div>
    <div>
      <div fsNavSupertitle></div>
      <div fsNavTitle></div>
      <div fsNavSubtitle></div>
    </div>
  </div>
  <div class="actions" fsNavActions="right"></div>
</div>
