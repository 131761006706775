import { FsListConfig, PaginationStrategy } from '@firestitch/list';

export const ListDefaultConfig: FsListConfig = {
  queryParam: true,
  chips: true,
  persist: true,
  paging: {
    strategy: PaginationStrategy.Offset,
    limit: 25,
  },
};
