<div class="app-container">
  <img
    *ngIf="additionalLogo"
    class="additional-logo"
    [src]="additionalLogo">

  <div class="card-container">
    <router-outlet class="card-router-outlet"></router-outlet>
  </div>
</div>
