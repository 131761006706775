<fs-dialog>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="heading">
    <app-logo></app-logo>
    <button mat-icon-button mat-dialog-close aria-label="Close" type="button" tabindex="-1">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <mat-card>

    <app-workspace
      *ngIf="step === 0"
      [(environmentCreate)]="environmentCreate"
      (environmentCreateChange)="stepNext()"
    ></app-workspace>
    <app-project
      *ngIf="step === 1"
      [(environmentCreate)]="environmentCreate"
      (environmentCreateChange)="stepNext()"
    ></app-project>
    <app-invite
      *ngIf="step === 2"
      [environmentCreate]="environmentCreate"
      (completed)="close()"
    ></app-invite>
    <app-progress
      [step]="step"
    ></app-progress>
  </mat-card>
</fs-dialog>
