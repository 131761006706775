import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class ContentQuestionData<T = any> {

  constructor(private _api: FsApi) { }

  public get(id: number, query: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.get(
      `contents/questions/${id}`,
      query,
      {
        key: 'question',
        ...config,
      },
    );
  }

  public gets(query: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.request(
      'GET',
      'contents/questions',
      query,
      {
        key: 'questions',
        ...config,
      },
    );
  }

  public put(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.put(
      `contents/questions/${data.id}`,
      data,
      {
        key: 'question',
        ...config,
      },
    );
  }

  public post(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      'contents/questions',
      data,
      {
        key: 'question',
        ...config,
      },
    );
  }

  public delete(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.delete(
      `contents/questions/${data.id}`,
      data,
      {
        key: 'question',
        ...config,
      },
    );
  }

  public answersOrder(id: number, data: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      `contents/questions/${id}/answers/order`,
      data,
      {
        key: 'contentQuestionAnswers',
        ...config,
      },
    );
  }

  public answersSubmit(id: number, data: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      `contents/questions/${id}/answers/submit`,
      data,
      {
        key: '',
        ...config,
      },
    );
  }

  public answersPost(id: number, data: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      `contents/questions/${id}/answers`,
      data,
      {
        key: 'contentQuestionAnswers',
        ...config,
      },
    );
  }

  public save(data: any, config: RequestConfig = {}): Observable<T> {
    return (data.id)
      ? this.put(data, config)
      : this.post(data, config);
  }

}
