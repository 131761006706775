import { Account, IClient } from '@common/interfaces';

import { ISubject } from '../interfaces';


export function staffToSubject(data: IClient | Account, isClient: boolean): ISubject {
  if (!data) {
    return null;
  }

  return {
    name: data.name,
    type: isClient ? 'client' : (data as Account).type,
    id: data.id,
  };
}
