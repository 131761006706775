import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';

import { Project } from '@app/common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class ProjectData {

  constructor(private _fsApi: FsApi) { }

  public create(data: Project = { id: null }): Project {
    return data;
  }

  public get(id: number, query = {}): Observable<any> {
    return this._fsApi.get(`projects/${id}`, query, { key: 'project' });
  }

  public gets(data = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', 'projects', data, { key: 'projects', ...config });
  }

  public put(project, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.put(`projects/${project.id}`, project, { key: 'project', ...config });
  }

  public post(project): Observable<any> {
    return this._fsApi.post('projects', project, { key: 'project' });
  }

  public delete(project): Observable<any> {
    return this._fsApi.delete(`projects/${project.id}`, project, { key: 'project' });
  }

  public accountsGets(id: number, data = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', `projects/${id}/accounts`, data, { key: 'projectAccounts', ...config });
  }

  public accountsGet(projectId: number, id: number, data = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', `projects/${projectId}/accounts/${id}`, data, { key: 'projectAccount', ...config });
  }

  public accountsPost(data: any = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('POST', `projects/${data.projectId}/accounts`, data, { key: 'projectAccount', ...config });
  }

  public accountsPut(data: any = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('PUT', `projects/${data.projectId}/accounts/${data.id}`, data, { key: 'projectAccount', ...config });
  }

  public accountsDelete(data: any = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('DELETE', `projects/${data.projectId}/accounts/${data.id}`, data, { key: 'projectAccount', ...config });
  }

  public accountsSave(data: any): Observable<any> {
    if (data.id) {
      return this.accountsPut(data);
    }

    return this.accountsPost(data);
  }

  public save(data: any): Observable<any> {
    if (data.id) {
      return this.put(data);
    }

    return this.post(data);
  }

}
