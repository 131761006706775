import { AccountType } from '../enums';

export const AccountTypes = [
  { name: 'Candidate', value: AccountType.Candidate },
  { name: 'Field Staff', value: AccountType.FieldStaff },
  { name: 'Outsourced Staff', value: AccountType.OutsourcedStaff },
  { name: 'Contact', value: AccountType.Contact },
  { name: 'Office Staff', value: AccountType.OfficeStaff },
  { name: 'Api', value: AccountType.Api },
];
