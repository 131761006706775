import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class ProjectSidenavService {

  private _project$ = new BehaviorSubject<any>(null);

  public set project(project) {
    this._project$.next(project);
  }

  public get project() {
    return this._project$.getValue();
  }

  public get project$() {
    return this._project$;
  }

}
