import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';

import { IShiftAccount } from '@app/common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class ShiftAccountData {

  constructor(private _fsApi: FsApi) { }

  public create(data: IShiftAccount = { id: null }): IShiftAccount {

    return data;
  }

  public get(id: number, query: any = {}): Observable<any> {
    return this._fsApi.get(`shiftaccounts/${id}`, query, { key: 'shiftAccount' });
  }

  public gets(query = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', 'shiftaccounts', query, { key: 'shiftAccounts', ...config });
  }

  public post(data): Observable<any> {
    return this._fsApi.post('shiftaccounts', data, { key: 'shiftAccount' });
  }

  public put(data, config: RequestConfig = {}) {
    return this._fsApi.put(`shiftaccounts/${data.id}`, data, { key: 'shiftAccount', ...config });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }

    return this.post(data);
  }

}
