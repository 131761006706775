import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { CardComponent } from '@libs/layouts/card';
import { SideNavComponent } from '@libs/layouts/side-nav';

import {
  AccountingAclGuard,
  AdminExperienceGuard,
  ClientAclGuard,
  ContactExperienceGuard,
  Force2faGuard,
  LoggedInGuard,
  LoggedOutGuard,
  ShiftAclGuard,
  TempStaffExperienceGuard,
} from '@app/common/guards';


export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: '',
    component: SideNavComponent,
    data: { bodyClass: 'body-sidenav' },
    canActivate: [LoggedInGuard],
    children: [
      {
        path: 'tempstaff',
        canActivate: [TempStaffExperienceGuard],
        loadChildren: () => import('./temp-staff').then((m) => m.TempStaffModule),
      },
      {
        path: 'contact',
        canActivate: [ContactExperienceGuard],
        loadChildren: () => import('./contact').then((m) => m.ContactModule),
      },
      {
        path: '',
        canActivate: [Force2faGuard, AdminExperienceGuard],
        children: [
          { path: 'account', loadChildren: () => import('./account').then((m) => m.AccountModule) },
          { path: 'dashboard', loadChildren: () => import('./dashboard').then((m) => m.DashboardModule) },
          { path: 'personnel', loadChildren: () => import('./personnel').then((m) => m.PersonnelModule) },
          {
            path: 'clients',
            canActivate: [ClientAclGuard],
            loadChildren: () => import('./clients').then((m) => m.ClientsModule),
          },
          {
            path: 'accounting',
            canActivate: [AccountingAclGuard],
            loadChildren: () => import('./accounting').then((m) => m.AccountingModule),
          },
          {
            path: 'jobs',
            canActivate: [ShiftAclGuard],
            loadChildren: () => import('./jobs').then((m) => m.JobsModule),
          },
          {
            path: 'schedule',
            canActivate: [ShiftAclGuard],
            loadChildren: () => import('./schedule').then((m) => m.ScheduleModule),
          },
          //{ path: 'workspace', loadChildren: () => import('./environment').then((m) => m.EnvironmentModule) },
          {
            path: 'drive', loadChildren: () => import('@libs/drive').then((m) => m.DriveModule),
            data: {
              fsNav: { root: true },
            },
          },
          {
            path: 'admin',
            loadChildren: () => import('./admin').then((m) => m.AdminModule),
            data: {
              fsNav: { root: true },
            },
          },
          {
            path: 'system',
            loadChildren: () => import('./system').then((m) => m.SystemModule),
            data: {
              fsNav: { root: true },
            },
          },
        ],
      },
      {
        path: 'notfound',
        loadChildren: () => import('@libs/not-found').then((m) => m.NotFoundModule),
      },
    ],
  },
  {
    path: '',
    component: CardComponent,
    children: [
      {
        path: 'staff',
        loadChildren: () => import('./staff').then((m) => m.StaffModule),
        data: { bodyClass: 'body-staff' },
      },
    ],
  },
  {
    path: '',
    component: CardComponent,
    data: { bodyClass: 'body-card' },
    canActivate: [LoggedOutGuard],
    children: [
      {
        path: 'password',
        loadChildren: () => import('./password').then((m) => m.PasswordModule),
        data: { bodyClass: 'body-card body-password' },
      },
      {
        path: 'invite',
        loadChildren: () => import('./invite').then((m) => m.InviteModule),
        data: { bodyClass: 'body-card' },
      },
      {
        path: 'activation',
        loadChildren: () => import('./activation').then((m) => m.ActivationModule),
        data: { bodyClass: 'body-card' },
      },
      {
        path: 'signin',
        loadChildren: () => import('./signin').then((m) => m.SigninModule),
      },
      {
        path: 'signup',
        loadChildren: () => import('./signup').then((m) => m.SignupModule),
        data: { bodyClass: 'body-card body-signup' },
      },
      {
        path: 'twofactor',
        loadChildren: () => import('./two-factor').then((m) => m.TwoFactorModule),
        data: { bodyClass: 'body-card' },
      },
    ],
  },
  {
    path: 'blank',
    component: CardComponent,
  },
  { path: '**', redirectTo: '/notfound' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        useHash: (+document.location.port === 8080 || document.location.protocol === 'file:'),
        preloadingStrategy: PreloadAllModules,
        relativeLinkResolution: 'legacy',
      },
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
