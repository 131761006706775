<app-header></app-header>
<div class="app-container" cdkScrollable>
  <div class="app-content">
    <div class="app-scroll-content" name="app-scroll">
      <div class="container container-side-nav">
        <mat-sidenav-container>
          <mat-sidenav          
            #sidenav
            class="sidenav"
            [disableClose]="disableClose"
            [mode]="sidenavService.mode"
            [opened]="sidenavService.opened"
            [fixedInViewport]="true">
              <div class="sidenav-container">
                <div class="logo">
                  <a class="image" routerLink="/">
                    <app-logo type="light"></app-logo>
                  </a>
                </div>

                <fs-sidenav
                  #sideNavMainEl
                  [config]="sideNavMain"
                  (click)="clickNav($event)">
                </fs-sidenav>

                <div class="spacer"></div>

                <ng-template #contactSignoffTotalRef>
                  <div class="notification">
                    Dashboard
                    <div
                        *ngIf="contactSignoffTotal"
                        class="count"
                        [attr.data-count]="contactSignoffTotal">
                      {{contactSignoffTotal || ''}}
                    </div>
                  </div>
                </ng-template>

                <!-- <ng-template #notifications>
                  <ng-container *ngIf="account">
                    <div class="badge">
                      <fs-badge [image]="account.avatar?.tiny"></fs-badge>
                      <div class="count" [attr.data-count]="unreadNotifications">{{unreadNotifications || ''}}</div>
                    </div>
                    <a class="name" routerLink="/account" (click)="$event.stopPropagation();">
                      {{account.name}}
                    </a>
                  </ng-container>
                </ng-template> -->

                <fs-sidenav
                  [config]="sideNavBottom"
                  (click)="clickNav($event)">
                </fs-sidenav>
              </div>

          </mat-sidenav>
          <mat-sidenav-content>
            <navbar></navbar>
            <router-outlet></router-outlet>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </div>
    </div>
  </div>
</div>
