import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';
import { parseLocal } from '@firestitch/date';
import { FsTransferService } from '@firestitch/transfer';

import { Observable } from 'rxjs';

import { isString } from 'lodash-es';
import { isBefore, isEqual } from 'date-fns';

import { IEmployeeCertification } from '@app/common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class EmployeeCertificationData {

  constructor(
    private _transfer: FsTransferService,
    private _api: FsApi,
  ) { }

  public create(data: IEmployeeCertification = { id: null }): IEmployeeCertification {

    if (isString(data.issueDate)) {
      data.issueDate = parseLocal(data.issueDate);
    }

    if (isString(data.expiryDate)) {
      data.expiryDate = parseLocal(data.expiryDate);
    }

    data.expired = data.expiryDate &&
      (
        isBefore(data.expiryDate, new Date()) ||
        isEqual(data.expiryDate, new Date())
      );

    return data;
  }

  public get(accountId: number, id: number, query: any = {}): Observable<any> {
    return this._api.get(`accounts/${accountId}/certifications/${id}`, query, { key: 'employeeCertification' });
  }

  public gets(accountId: number, query = {}, config: RequestConfig = {}): Observable<any> {
    return this._api.request('GET', `accounts/${accountId}/certifications`, query, { key: 'employeeCertifications', ...config });
  }

  public put(accountId, data, config: RequestConfig = {}): Observable<any> {
    return this._api.put(`accounts/${accountId}/certifications/${data.id}`, data, { key: 'employeeCertification', ...config });
  }

  public post(accountId, data, config: RequestConfig = {}): Observable<any> {
    return this._api.post(`accounts/${accountId}/certifications`, data, { key: 'employeeCertification', ...config });
  }

  public delete(accountId: number, data): Observable<any> {
    return this._api.delete(`accounts/${accountId}/certifications/${data.id}`, data, { key: 'employeeCertification' });
  }
  public save(accountId, data, config: RequestConfig = {}): Observable<any> {
    if (data.id) {
      return this.put(accountId, data, config);
    }

    return this.post(accountId, data, config);
  }

  public download(accountId: number, data): void {
    this._transfer.post(`accounts/${accountId}/certifications/${data.id}/download`);
  }

}
