import { Injectable, OnDestroy } from '@angular/core';

import { fromEvent, Subject, Observable } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class SidenavService implements OnDestroy {

  public opened = false;
  public mode: 'over' | 'push' | 'side';
  public topGap: number;
  public resize$: Observable<any>;

  private smallBreakpoint = 1023;
  private _destroy$ = new Subject();

  constructor() {
    this._updateSettings();

    this.resize$ = fromEvent(window, 'resize')
      .pipe(
        debounceTime(300),
      );

    this.resize$
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe((e: any) => {
        this._updateSettings();
      });
  }

  public toggle(): void {
    this.opened = !this.opened;
  }

  public open(): void {
    this.opened = true;
  }

  public close(): void {
    this.opened = false;
  }

  public hide(): void {
    if (window.innerWidth < this.smallBreakpoint) {
      this.opened = false;
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _updateSettings(): void {
    if (window.innerWidth < this.smallBreakpoint) {
      this.mode = 'over';
      this.topGap = 56;
      this.opened = false;
    } else {
      this.mode = 'side';
      this.opened = true;
      this.topGap = 0;
      this.opened = true;
    }
  }

}
