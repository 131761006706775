import { Injectable } from '@angular/core';


import { FsApi } from '@firestitch/api';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SigninService } from '@common/services';


@Injectable({
  providedIn: 'root',
})
export class SignupService {

  constructor(
    private _api: FsApi,
    private _signinService: SigninService,
  ) { }

  public postEmail(data: any): Observable<any> {
    return this._api.post('signup/email', data)
      .pipe(
        tap((response: any) => this._signinService.setSession(response)),
      );
  }

  public validateEmail(email: string): Observable<any> {
    return this._api.post('signup/email/validate', { email });
    // .pipe(
    //   tap((response: any) => this._signinService.processSignin(response)),
    // );
  }

  public postFacebook(accessToken: string, data: any = {}): Observable<any> {
    data.accessToken = accessToken;

    return this._api.post('signup/facebook', data)
      .pipe(
        tap((response: any) => this._signinService.processSignin(response)),
      );
  }

  public postGoogle(idToken: string, accessToken: string, data: any = {}): Observable<any> {
    data.idToken = idToken;
    data.accessToken = accessToken;

    return this._api.post('signup/google', data)
      .pipe(
        tap((response: any) => this._signinService.processSignin(response)),
      );
  }
}
