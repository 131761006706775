import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { AccountType } from '@common/enums';

import { SessionService } from '@app/core';

import { experienceDefaultUrl } from '../helpers';


@Injectable({
  providedIn: 'root',
})
export class ContactExperienceGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _sessionService: SessionService,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {

    const account = this._sessionService.account();
    if (account.type === AccountType.Contact) {
      return true;
    }

    return this._router.createUrlTree(experienceDefaultUrl(account.type));
  }

}
