import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';
import { FsTransferService } from '@firestitch/transfer';

import { Observable } from 'rxjs';

import { IFile } from '@app/common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class FileData {

  constructor(
    private _fsApi: FsApi,
    private _transfer: FsTransferService,
  ) { }

  public create(data: IFile = { id: null }): IFile {
    return data;
  }

  public get(objectId: number, id: number, query: any = {}): Observable<any> {
    return this._fsApi.get(`objects/${objectId}/files/${id}`, query, { key: 'file' });
  }

  public gets(objectId: number, query = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', `objects/${objectId}/files`, query, { key: 'files', ...config });
  }

  public put(objectId: number, data, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.put(`objects/${objectId}/files/${data.id}`, data, { key: 'file', ...config });
  }

  public post(objectId: number, data): Observable<any> {
    return this._fsApi.post(`objects/${objectId}/files`, data, { key: 'file' });
  }

  public delete(objectId: number, data): Observable<any> {
    return this._fsApi.delete(`objects/${objectId}/files/${data.id}`, data, { key: 'file' });
  }

  public save(objectId: number, data): Observable<any> {
    if (data.id) {
      return this.put(objectId, data);
    }

    return this.post(objectId, data);
  }

  public download(objectId: number, data): void {
    this._transfer.post(`objects/${objectId}/files/${data.id}/download`);
  }

}
