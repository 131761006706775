import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { MatSidenav } from '@angular/material/sidenav';

import { AclAccess } from '@firestitch/acl';
import { BodyClassRenderer } from '@firestitch/body';
import { Item, ItemActionType, SidenavConfig } from '@firestitch/sidenav';
import { FsStore } from '@firestitch/store';

import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, skip, takeUntil } from 'rxjs/operators';

import { AclPermission } from '@common/enums';
import { Account } from '@common/interfaces';
import { AclService } from '@common/services';

import { EventEmitterType, WindowMessageEvent, WindowMessageEventType } from '@app/common';
import { experienceDefaultUrl } from '@app/common/helpers';
import {
  AuthService, EventEmitterService, PortalService,
  ProjectSidenavService, WindowMessageService,
} from '@app/core/services';

import { SidenavService } from '../../services/sidenav.service';
import { EnvironmentMenuComponent } from '../environment-menu/environment-menu.component';


@Component({
  selector: 'app-page-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavComponent implements OnInit, OnDestroy {

  @ViewChild('sidenav', { static: true })
  public sidenav: MatSidenav;

  @ViewChild('sideNavMainEl', { static: true })
  public sideNavMainEl;

  @ViewChild('contactSignoffTotalRef', { static: true })
  public contactSignoffTotalRef: TemplateRef<any>;

  public disableClose: boolean;
  public sideNavMain: SidenavConfig = { items: [] };
  public sideNavBottom: SidenavConfig = { items: [] };
  public workspaceSidenavItems = [];
  public unreadNotifications: number;
  public account: Account;
  public contactSignoffTotal: number;

  private _destroy$ = new Subject();

  constructor(
    public sidenavService: SidenavService,
    private _store: FsStore,
    private _router: Router,
    private _aclQueryService: AclService,
    private _bodyClassRenderer: BodyClassRenderer,
    private _windowMessageService: WindowMessageService,
    private _cdRef: ChangeDetectorRef,
    private _projectSidenavService: ProjectSidenavService,
    private _portalService: PortalService,
    private _authService: AuthService,
    private _eventEmitterService: EventEmitterService,
  ) {
    this._bodyClassRenderer.addBodyClass('body-side-nav');
    this.disableClose = this.sidenavService.mode !== 'over';

    this.sidenavService.resize$
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this.disableClose = this.sidenavService.mode !== 'over';
        if (this.sidenavService.opened) {
          this.sidenav.open();
        }
        this._cdRef.markForCheck();
      });

    this._projectSidenavService.project$
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this._loadMainSidenav();
      });

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => window.location.pathname),
        distinctUntilChanged(),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this._loadMainSidenav();
      });

    this._eventEmitterService.event$
      .pipe(
        filter((event) => event.type === EventEmitterType.ContactSignoffTotal),
        takeUntil(this._destroy$),
      )
      .subscribe((event) => {
        this.contactSignoffTotal = event.data;
        this._cdRef.markForCheck();
      });
  }

  public ngOnInit(): void {
    this.account = this._store.get('account');
    this._buildSidenavBottom();

    this._store.observe('permissions')
      .pipe(
        skip(1),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this._buildSidenavBottom();
      });

    this._store.observe('environment')
      .pipe(
        skip(1),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this._buildSidenavBottom();
      });

    this._store.observe('account')
      .pipe(
        skip(1),
        takeUntil(this._destroy$),
      )
      .subscribe((store) => {
        this.account = store.value;
        this._buildSidenavBottom();
      });

    this._windowMessageService.event$
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe((event: WindowMessageEvent) => {
        switch (event.type) {
          case WindowMessageEventType.NotificationsUnread:
            this.unreadNotifications = event.data;
            this._cdRef.markForCheck();
            break;
        }
      });
  }

  public clickNav($event: any): void {
    this.sidenavService.hide();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _loadMainSidenav(): void {
    if (this._portalService.isPortalAdmin) {
      this.sideNavMain = {
        items: [
          {
            label: 'Dashboard',
            path: '/dashboard',
            icon: 'insert_chart',
          },
          {
            label: 'Personnel',
            path: this._personnelLink(),
            hide: !this._aclQueryService.hasPermissionTempStaff(AclAccess.Read) &&
              !this._aclQueryService.hasPermissionOfficeStaff(AclAccess.Read),
            paths: [/personnel/],
            icon: 'people',
          },
          {
            label: 'Clients',
            path: '/clients',
            hide: !this._aclQueryService.hasPermissionClient(AclAccess.Read),
            paths: [/clients/],
            icon: 'domain',
          },
          {
            label: 'Jobs',
            path: '/jobs',
            paths: [/jobs/],
            hide: !this._aclQueryService.hasPermissionShift(AclAccess.Read),
            icon: 'folder',
          },
          {
            label: 'Schedule',
            path: '/schedule',
            paths: [/^\/schedule/],
            hide: !this._aclQueryService.hasPermissionShift(AclAccess.Read),
            icon: 'event_note',
          },
          {
            label: 'Accounting',
            path: '/accounting/weeks',
            paths: [/accounting/],
            hide: !this._aclQueryService.hasPermissionAccounting(AclAccess.Read),
            icon: 'account_balance',
          },
        ],
      };
    } else if (this._portalService.isPortalTempStaff) {
      this.sideNavMain = {
        items: [
          {
            label: 'Calendar',
            path: '/tempstaff/calendar',
            icon: 'event',
          },
          // {
          //   label: 'Shifts',
          //   path: '/tempstaff/shifts',
          //   icon: 'work',
          // },
        ],
      };
    } else if (this._portalService.isPortalContact) {
      this.sideNavMain = {
        items: [
          {
            label: 'Dashboard',
            template: this.contactSignoffTotalRef,
            class: 'contact-dashboard',
            path: '/contact/dashboard',
            icon: 'insert_chart',
          },
          {
            label: 'Shifts',
            path: '/contact/shifts',
            paths: [/contact\/shifts/],
            icon: 'work',
          },
        ],
      };
    }

    this._cdRef.markForCheck();
  }

  private _buildSidenavBottom(): void {
    let items: Item[] = [];

    // if (this._portalService.isPortalAdmin) {
    //   items = this._appendEnvironmentNav(items);
    // }

    items = this._appendAccountNav(items);

    if (this._portalService.isPortalAdmin) {
      items = this._appendAdminNav(items);
      items = this._appendSystemNav(items);
    }

    items = this._appendSignoutNav(items);

    this.sideNavBottom = { items };
    this._cdRef.markForCheck();
  }

  private _appendEnvironmentNav(items: Item[]): Item[] {
    const environment = this._store.get('environment');
    const hasWorkspaceWrite = environment ?
      this._aclQueryService.hasWrite(AclPermission.Environment, environment.id) :
      false;

    const item: any = {
      superlabel: 'Workspace',
      label: 'Not Selected',
      exact: true,
      actions: [
        {
          type: ItemActionType.Menu,
          icon: 'more_vert',
          menu: {
            component: EnvironmentMenuComponent,
            data: { environment, hasWorkspaceWrite },
          },
        },
      ],
    };

    if (environment) {
      item.label = environment.name;
      item.image = environment.avatar?.tiny;
      if (this._aclQueryService.hasPermissionAdmin() || hasWorkspaceWrite) {
        item.path = `/workspace/${environment.id}`;
        item.items = [
          { label: 'Members', path: `/workspace/${environment.id}/members`, exact: false },
          { label: 'Settings', path: `/workspace/${environment.id}/settings`, exact: false },
        ];
      }
    }

    return [
      ...items,
      item,
    ];
  }

  private _appendAccountNav(items: Item[]): Item[] {
    if (!this.account) {
      return items;
    }

    return [
      ...items,
      {
        label: `${this.account.firstName} ${this.account.lastName}`,
        superlabel: 'My Account',
        path: [
          ...experienceDefaultUrl(this.account.type),
          'account',
          'overview',
        ].join('/'),
        image: this.account.avatar.small,
      },
    ];
  }

  private _appendAdminNav(items: Item[]): Item[] {
    if (!this._aclQueryService.hasPermissionAdmin()) {
      return items;
    }

    return [
      ...items,
      {
        label: 'Admin',
        path: '/admin',
        icon: 'settings',
        class: 'admin',
        items: [
          {
            label: 'Accounts',
            path: '/admin/accounts',
            exact: false,
          },
          {
            label: 'Settings',
            path: '/admin/settings',
            exact: false,
          },
          {
            label: 'Messages',
            path: '/admin/message',
            exact: false,
          },
          {
            label: 'Audits',
            path: '/admin/audits',
            exact: false,
          },
        ],
      },
    ];
  }

  private _appendSystemNav(items: Item[]): Item[] {
    if (!this._aclQueryService.hasPermissionSystem()) {
      return items;
    }

    return [
      ...items,
      {
        label: 'System',
        path: '/system',
        icon: 'personal_video',
        items: [
          {
            label: 'Settings',
            path: '/system/settings',
          },
          {
            label: 'Crons',
            path: '/system/crons',
          },
          {
            label: 'File Manager',
            path: '/system/files',
          },
          {
            label: 'Processes',
            path: '/system/processes',
          },
          {
            label: 'Logs',
            path: '/system/logs',
          },
          {
            label: 'Info',
            path: '/system/info',
          },
          {
            label: 'Metrics',
            path: '/system/metrics',
          },
        ],
      },
    ];
  }

  private _appendSignoutNav(items: Item[]): Item[] {
    return [
      ...items,
      {
        label: 'Sign Out',
        icon: 'exit_to_app',
        click: () => {
          this._authService.signout();
        },
      },
    ];
  }

  private _personnelLink(): string[] {
    const urlData: string[] = ['/personnel'];

    if (this._aclQueryService.hasPermissionTempStaff(AclAccess.Read)) {
      urlData.push('tempstaff');
    } else if (this._aclQueryService.hasPermissionOfficeStaff(AclAccess.Read)) {
      urlData.push('officestaff');
    }

    return urlData;
  }

}
