import { PreferenceType } from '@common/enums';


export const PreferenceTypes = [
  { name: 'Do Not Place', value: PreferenceType.DoNotPlace },
  { name: 'Needs Improvement', value: PreferenceType.NeedsImprovement },
  { name: 'Neutral', value: PreferenceType.Neutral },
  { name: 'Good Fit', value: PreferenceType.GoodFit },
  { name: 'Like', value: PreferenceType.Like },
];
