import { Injectable, OnDestroy } from '@angular/core';

import { AclAccess, AclEntry, AclRequire, FsAcl } from '@firestitch/acl';
import { FsStore } from '@firestitch/store';

import { Subject } from 'rxjs';

import { AclPermission } from '@common/enums';


@Injectable({
  providedIn: 'root',
})
export class AclService implements OnDestroy {

  public readonly changed$ = new Subject();

  constructor(
    private _store: FsStore,
    private _acl: FsAcl,
  ) {
    this._store.observe('permissions')
      .subscribe((event: any) => {
        const entries: AclEntry[] = [];
        if (event.value) {
          event.value.forEach((objectPermission) => {
            objectPermission.permissions.forEach((permission) => {
              entries.push({
                objectId: objectPermission.objectId,
                permissions: [
                  {
                    value: permission.value,
                    access: permission.access,
                  },
                ],
              });
            });
          });
        }

        _acl.setEntries(entries);
        this.changed$.next();
      });
  }

  public hasPermissionAdmin(access: AclAccess = AclAccess.Write): boolean {
    return this._acl.has(AclPermission.Admin, access);
  }

  public hasPermissionSystem(access: AclAccess = AclAccess.Write): boolean {
    return this._acl.has(AclPermission.System, access);
  }

  public hasPermissionShiftActual(access: AclAccess = AclAccess.Write): boolean {
    return this._acl.has(AclPermission.ShiftActual, access);
  }

  public hasPermissionTempStaff(access: AclAccess = AclAccess.Write): boolean {
    return this._acl.has(AclPermission.TempStaff, access);
  }

  public hasPermissionOfficeStaff(access: AclAccess = AclAccess.Write): boolean {
    return this._acl.has(AclPermission.OfficeStaff, access);
  }

  public hasPermissionClient(access: AclAccess = AclAccess.Write): boolean {
    return this._acl.has(AclPermission.Client, access);
  }

  public hasPermissionShift(access: AclAccess = AclAccess.Write): boolean {
    return this._acl.has(AclPermission.Shift, access);
  }

  public hasPermissionAccounting(access: AclAccess = AclAccess.Write): boolean {
    return this._acl.has(AclPermission.Accounting, access);
  }

  public hasRead(permissions: string[] | string, object?: any, _require?: AclRequire): boolean {
    return this.has(permissions, AclAccess.Read, object, _require);
  }

  public hasWrite(permissions: string[] | string, object?: any, _require?: AclRequire): boolean {
    return this.has(permissions, AclAccess.Write, object, _require);
  }

  public hasFull(permissions: string[] | string, object?: any, _require?: AclRequire): boolean {
    return this.has(permissions, AclAccess.Full, object, _require);
  }

  public has(
    permissions: string[] | string,
    access?: AclAccess,
    objects?: any,
    _require?: AclRequire,
  ): boolean {
    if (permissions === AclPermission.Timesheetsignoff) {
      return this._acl.has(permissions, access, objects, _require);
    }

    return this.hasPermissionAdmin() || this._acl.has(permissions, access, objects, _require);
  }

  public ngOnDestroy(): void {
    this.changed$.next();
    this.changed$.complete();
  }

}
