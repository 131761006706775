import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { ObjectDrawerService } from './services';


@NgModule({
  imports: [
    CommonModule,

    MatIconModule,
    MatButtonModule,
  ],
  providers: [
    ObjectDrawerService,
  ],
})
export class DrawerModule {}
