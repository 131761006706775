import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree,
} from '@angular/router';

import { FsCookie } from '@firestitch/cookie';
import { parse } from '@firestitch/date';
import { FsMessage, MessageMode } from '@firestitch/message';

import { AccountType } from '@common/enums';
import { Account } from '@common/interfaces';
import { isAfter } from 'date-fns';

import { SessionService } from '@app/core/services';


@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _sessionService: SessionService,
    private _message: FsMessage,
    private _cookie: FsCookie,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const expiry = parse(this._cookie.get('Token-Expiry'));
    if (!expiry || isAfter(new Date(), expiry)) {

      if (expiry) {
        this._message.error('Your session has expired. Please signin again.',
          { mode: MessageMode.Toast });
      }

      return this.createSigninUrlTree();
    }

    const account: Account = this._sessionService.account();

    if (account) {
      if (account.type === AccountType.Candidate) {
        this._router.navigate(['/staff']);
      } else if (account.passwordChange) {
        this._router.navigate(['/signin/passwordchange'], { queryParams: { redirect: state.url } });

        return false;
      }
    }

    return true;
  }

  public createSigninUrlTree(): UrlTree {
    const redirect = window.location.pathname + window.location.search;

    return this._router.createUrlTree(['/signin'],
      { queryParams: { redirect } });
  }

}
