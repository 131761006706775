import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';

import { IOffice } from '@common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class OfficeData {

  constructor(private _fsApi: FsApi) { }

  public create(data: IOffice = { id: null }): IOffice {
    data.address = data.address || {};

    return data;
  }

  public get(id: number, query: any = {}): Observable<any> {
    return this._fsApi.get(`offices/${id}`, query, { key: 'office' });
  }

  public gets(query = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', 'offices', query, { key: 'offices', ...config });
  }

  public put(data, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.put(`offices/${data.id}`, data, { key: 'office', ...config });
  }

  public post(data): Observable<any> {
    return this._fsApi.post('offices', data, { key: 'office' });
  }

  public delete(data): Observable<any> {
    return this._fsApi.delete(`offices/${data.id}`, data, { key: 'office' });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }

    return this.post(data);
  }

}
