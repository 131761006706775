<!-- <form fsForm [submit]="save"> -->
<fs-dialog>
  <!-- <div mat-dialog-title>Bulk Update Shift Division and Position</div> -->
  <mat-dialog-content>
    <div fxLayout="column">

    </div>
  </mat-dialog-content>
  <!-- <mat-dialog-actions>
    <button mat-button type="submit" color="primary">Update</button>
    <button mat-button mat-dialog-close type="button">Cancel</button>
  </mat-dialog-actions> -->
</fs-dialog>
<!-- </form> -->
