import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';
import { parse } from '@firestitch/date';
import { FsTransferService } from '@firestitch/transfer';

import { Observable } from 'rxjs';

import { ShiftGender } from '@common/enums';

import { IShift } from '../../main/src/app/common/interfaces/shift';


@Injectable({
  providedIn: 'root',
})
export class ShiftData {

  constructor(
    private _fsApi: FsApi,
    private _transfer: FsTransferService,
  ) { }

  public create(data: IShift = { id: null }): IShift {
    data.gender = data.gender || ShiftGender.Any;
    data.certifications = data.certifications || [];
    data.skills = data.skills || [];
    data.startDate = data.startDate ? parse(data.startDate) : null;
    data.endDate = data.endDate ? parse(data.endDate) : null;

    return data;
  }

  public get(id: number, query: any = {}): Observable<IShift> {
    return this._fsApi.get(`shifts/${id}`, query, { key: 'shift' });
  }

  public gets(query = {}, config: RequestConfig = {}): Observable<IShift[]> {
    return this._fsApi.request('GET', 'shifts', query, { key: 'shifts', ...config });
  }

  public put(data, config: RequestConfig = {}): Observable<IShift> {
    return this._fsApi.put(`shifts/${data.id}`, data, { key: 'shift', ...config });
  }

  public post(data): Observable<IShift> {
    return this._fsApi.post('shifts', data, { key: 'shift' });
  }

  public unassign(shiftId: number, data): Observable<any> {
    return this._fsApi.post(`shifts/${shiftId}/unassign`, data, { key: null });
  }

  public assign(shiftId: number, data): Observable<any> {
    return this._fsApi.post(`shifts/${shiftId}/assign`, data, { key: null });
  }

  public bulk(data): Observable<any> {
    return this._fsApi.post('shifts/bulk', data, { key: null });
  }

  public bulkDivisionPosition(data): Observable<any> {
    return this._fsApi.post('shifts/bulk/divisionposition', data, { key: null });
  }

  public stats(query: any = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', 'shifts/stats', query, { key: null, ...config });
  }

  public schedule(query = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', 'shifts/schedule', query, { key: null, ...config });
  }

  public accountsGets(data): Observable<IShift> {
    return this._fsApi.get('shifts/accounts', data, { key: 'accounts' });
  }

  public delete(data): Observable<IShift> {
    return this._fsApi.delete(`shifts/${data.id}`, data, { key: 'shift' });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }

    return this.post(data);
  }

  public export(data: any = {}): void {
    this._transfer.post('shifts/export', data);
  }

  public checkinStats(query = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', 'shifts/stats/checkinstats', query, { key: null, ...config });
  }

  public signoffStats(query = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', 'shifts/stats/signoff', query, { key: null, ...config });
  }

  public signOff(
    shifts: any[],
    checkinShift: any = null,
    config: RequestConfig = {},
  ): Observable<any> {

    return this._fsApi.post(
      'shifts/signoff',
      { shifts, checkinShift },
      {
        key: '',
        ...config,
      },
    );
  }

}
