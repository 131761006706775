<form fsForm [submit]="save" #form="ngForm">
  <fs-dialog>
    <div mat-dialog-title>{{ environment.id ? 'Edit Workspace' : 'Create Workspace'}}</div>
    <mat-dialog-content>
      <div fxLayout="column">
        <mat-form-field>
          <input matInput placeholder="Name" [(ngModel)]="environment.name" fsFormRequired name="name">
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button color="primary">{{ environment.id ? 'Save' : 'Create' }}</button>
      <button mat-button [mat-dialog-close]="null" type="button">Cancel</button>
    </mat-dialog-actions>
  </fs-dialog>
</form>
