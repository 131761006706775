import { Router } from '@angular/router';

import { DisplayApiError, FsApiResponseHandler, RequestConfig } from '@firestitch/api';
import { FsErrorMessage, FsMessage, MessageMode } from '@firestitch/message';

import { RedirectNotFoundApiError } from '../consts';
import { SignoutService } from '../services';


export class ApiHandler extends FsApiResponseHandler {

  public readonly API_ERROR_RESOURCE_NOT_FOUND = 404;
  public readonly API_ERROR_INVALID_AUTHORIZATION = 490;

  constructor(
    private _message: FsMessage,
    private _router: Router,
    private _errorMessage: FsErrorMessage,
    private _signoutService: SignoutService,
  ) {
    super();
  }

  public success(event, config: RequestConfig): void {
    if (event.body?.code >= 400) {
      this._errorMessage.showErrorMessage(event.body.message, event.body.exception);
    } else {
      event.body = event.body?.data;
      if (config.key && event.body) {
        event.body = event.body[config.key];
      }
    }
  }

  public error(event, config: RequestConfig): void {
    if (event.status === this.API_ERROR_RESOURCE_NOT_FOUND) {
      if (config.context?.get(RedirectNotFoundApiError) ?? true) {
        this._router.navigateByUrl('/notfound');
      }
    } else if (event.status === this.API_ERROR_INVALID_AUTHORIZATION) {
      this._message.error('Please signin to access this page', { mode: MessageMode.Toast });
      this._signoutService.signout();
    } else if (config.context?.get(DisplayApiError) ?? true) {
      this._errorMessage.processHttpErrorResponse(event);
    }
  }

}
