export enum ShiftState {
  Unassigned = 'unassigned',
  Tentative = 'tentative',
  Confirmed = 'confirmed',
  Cancelled = 'cancelled',
  Declined = 'declined',
  Sick = 'sick',
  Deleted = 'deleted',
  Draft = 'draft',
}
