import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { EventEmitterEvent } from '@app/common';
import { EventEmitterType } from '@app/common/enums';


@Injectable({
  providedIn: 'root',
})
export class EventEmitterService {

  private _event$ = new BehaviorSubject<EventEmitterEvent>({ type: null, data: null });

  constructor() { }

  public get event$(): BehaviorSubject<EventEmitterEvent> {
    return this._event$;
  }

  public emit(type: EventEmitterType, data?: any): void {
    this._event$.next({ type, data });
  }

}
