import { Injectable } from '@angular/core';

import { FsApi } from '@firestitch/api';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadData {

  constructor(private _api: FsApi) { }

  public image(file): Observable<any> {
    return this._api.post('upload/image', { file }, { key: 'url' });
  }

  public globalImage(file): Observable<any> {
    return this._api.post('upload/global/image', { file }, { key: 'url' });
  }

  public webm(file): Observable<any> {
    return this._api.post('upload/webm', { file }, { key: 'url' });
  }

}
