<form #form fsForm [submit]="save" *fsSkeletonForm="environmentCreate">
  <mat-card-content>
    <div fxLayout="column" fxLayoutAlign="start center">
      <h1>Name Your Specify Workspace</h1>
      <p>This could be the name of your company or organization</p>
      <mat-form-field class="full-width">
        <input matInput placeholder="Workspace Name" [(ngModel)]="environmentCreate.workspaceName" fsFormRequired name="workspace_name" autoFocus1>
        <mat-hint>This can be changed later</mat-hint>
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button type="submit" mat-raised-button color="primary" class="full-width">Continue</button>
  </mat-card-actions>
</form>
