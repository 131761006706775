import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';

import { IPayRate } from '@app/common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class PayRateData {

  constructor(private _fsApi: FsApi) { }

  public create(data: IPayRate = { id: null }): IPayRate {
    return data;
  }

  public get(accountId: number, id: number, query: any = {}): Observable<any> {
    return this._fsApi.get(`accounts/${accountId}/payrates/${id}`, query, { key: 'payRate' });
  }

  public gets(accountId: number, query = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', `accounts/${accountId}/payrates`, query, { key: 'payRates', ...config });
  }

  public put(accountId: number, data, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.put(`accounts/${accountId}/payrates/${data.id}`, data, { key: 'payRate', ...config });
  }

  public post(accountId: number, data): Observable<any> {
    return this._fsApi.post(`accounts/${accountId}/payrates`, data, { key: 'payRate' });
  }

  public delete(accountId: number, data): Observable<any> {
    return this._fsApi.delete(`accounts/${accountId}/payrates/${data.id}`, data, { key: 'payRate' });
  }

  public save(accountId: number, data): Observable<any> {
    if (data.id) {
      return this.put(accountId, data);
    }

    return this.post(accountId, data);
  }

}
