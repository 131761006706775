import { Injector } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { IDrawerConfig } from '@firestitch/drawer';
import { FsPrompt } from '@firestitch/prompt';

import { iif } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ShiftData } from '@common/data';

import { IShift } from '@app/common/interfaces';

import { ShiftAssignmentComponent } from '../shift-assignment/components';

import { Drawer } from './drawer.model';


export class ShiftAssignmentDrawer extends Drawer {

  private _shift: IShift = null;
  private _prompt: FsPrompt;
  private _shiftData: ShiftData;
  private _dialog: MatDialog;

  constructor(injector: Injector) {
    super(injector);
    this._shiftData = injector.get(ShiftData);
    this._dialog = injector.get(MatDialog);
    this._prompt = injector.get(FsPrompt);

    this._shift = this._shiftData.create();
  }

  public openObjectIdentifier(objectIdentifier: string): void {
    this._load({ identifier: objectIdentifier });
  }

  public openObject(object: any): void {
    this._load({ shiftId: object.id });
  }

  public close(): void {
    this.drawerRef.close();
  }

  private _load(query: any = {}): void {
    if (!this.drawerRef) {
      this._initAndOpenDrawer();
    }

    query = {
      ...query,
    };

    iif(
      () => !!query.shiftId,
      this._shiftData.get(query.shiftId, query),
      this._shiftData.gets(query)
        .pipe(
          map((response) => response[0]),
        ),
    )
      .pipe(
        takeUntil(this._destroy$),
      ).subscribe((response) => {
        this._shift = this._shiftData.create(response);
        this.drawerRef.dataChange({ shift: this._shift });
      });
  }

  private _initAndOpenDrawer(): void {
    const config: IDrawerConfig = {
      data: { shift: this._shift },
      disableClose: false,
      position: 'right',
      activeAction: 'preview',
      persist: {
        name: 'shift-assignment-drawer',
      },
      width: {
        main: {
          min: 500,
        },
      },
      actions: [
        // {
        //   icon: 'clear',
        //   type: FsDrawerAction.Button,
        //   close: true,
        // },
        // {
        //   icon: 'image',
        //   name: 'preview',
        //   type: FsDrawerAction.Button,
        //   tooltip: 'Preview',
        //   // click: () => {
        //   //   this._drawerRef.setActiveAction('preview');
        //   //   this._drawerRef.openSide();
        //   // }
        // },
        // {
        //   icon: 'share',
        //   name: 'related',
        //   type: FsDrawerAction.Button,
        //   tooltip: 'Related Objects',
        // },
        // {
        //   icon: 'attach_file',
        //   name: 'attachments',
        //   type: FsDrawerAction.Button,
        //   tooltip: 'Attachments',
        // },
        // {
        //   icon: 'restore',
        //   name: 'versions',
        //   type: FsDrawerAction.Button,
        //   tooltip: 'Versions',
        // },
        // {
        //   icon: 'file_download',
        //   type: FsDrawerAction.Button,
        //   tooltip: 'Export',
        //   click: () => {
        //     this._assetData.export(this._image);
        //   },
        // },
        // {
        //   icon: 'link',
        //   tooltip: 'Copy URL',
        //   type: FsDrawerAction.Button,
        //   click: (event) => {
        //     this._linkActionClicked(event);
        //   },
        // },
        // {
        //   icon: 'delete',
        //   type: FsDrawerAction.Button,
        //   click: (event) => {
        //     this._prompt.confirm({
        //       title: 'Confirm',
        //       template: 'Are you sure you would like to delete this Image?',
        //     }).pipe(
        //       takeUntil(this._destroy$),
        //     ).subscribe(() => {
        //       this._assetData.delete(this._image)
        //         .pipe(
        //           takeUntil(this._destroy$),
        //         )
        //         .subscribe(() => {
        //           this._deleted$.next(this._image);
        //           this.drawerRef.close();
        //         });
        //     });
        //   },
        // },
      ],
    };

    this.drawerRef = this._drawerService.open(ShiftAssignmentComponent, config);
  }

}
