import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';

import { ISkill } from '@app/common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class SkillData {

  constructor(private _fsApi: FsApi) { }

  public create(data: ISkill = { id: null }): ISkill {
    return data;
  }

  public get(id: number, query: any = {}): Observable<any> {
    return this._fsApi.get(`skills/${id}`, query, { key: 'skill' });
  }

  public gets(query = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', 'skills', query, { key: 'skills', ...config });
  }

  public put(data, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.put(`skills/${data.id}`, data, { key: 'skill', ...config });
  }

  public post(data): Observable<any> {
    return this._fsApi.post('skills', data, { key: 'skill' });
  }

  public delete(data): Observable<any> {
    return this._fsApi.delete(`skills/${data.id}`, data, { key: 'skill' });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }

    return this.post(data);
  }

}
