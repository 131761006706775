import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';
import { parse } from '@firestitch/date';

import { Observable } from 'rxjs';

import { differenceInMinutes } from 'date-fns';

import { IShiftActual } from '@app/common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class ShiftActualData {

  constructor(private _fsApi: FsApi) { }

  public create(data: IShiftActual = { id: null }): IShiftActual {
    data.preference = data.preference || 'none';
    data.actualDurationMinusBreak = 0;
    if (data.actualStartDate && data.actualEndDate) {
      data.actualDurationMinusBreak = differenceInMinutes(
        parse(data.actualEndDate),
        parse(data.actualStartDate),
      )
        - (data.actualUnpaidBreakMinutes || 0);
    }

    return data;
  }

  public get(shiftId: number, id: number, query: any = {}): Observable<any> {
    return this._fsApi.get(`shifts/${shiftId}/actuals/${id}`, query, { key: 'shiftActual' });
  }

  public gets(shiftId: number, query = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', `shifts/${shiftId}/actuals`, query, { key: 'shiftActuals', ...config });
  }

  public post(shiftId: number, data, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.post(`shifts/${shiftId}/actuals`, data, { key: 'shift', ...config });
  }

  public put(shiftId: number, data, config: RequestConfig = {}) {
    return this._fsApi.put(`shifts/${shiftId}/actuals/${data.id}`, data, { key: 'shift', ...config });
  }

  public save(shiftId: number, data, config: RequestConfig = {}): Observable<any> {
    if (data.id) {
      return this.put(shiftId, data, config);
    }

    return this.post(shiftId, data, config);
  }

  public activate(shiftId: number, shiftActualId: number, data: any = null): Observable<any> {
    return this._fsApi.post(`shifts/${shiftId}/actuals/${shiftActualId}/activate`, data, { key: 'shift' });
  }

}
