import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IObject, Project } from '@app/common/interfaces';
import { ObjectClass } from '@app/common/enums';
import { ObjectClasses } from '@app/common/consts';

import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class ObjectService {

  public get searchableObjectClasses() {
    return ObjectClasses.filter((objectClass) => {
      return objectClass.searchable;
    });
  }

  public get searchableObjectClassValues() {
    return this.searchableObjectClasses.map((objectClass) => {
      return objectClass.value;
    });
  }

  private maxRecentCount = 15;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _sessionService: SessionService,
  ) { }

  public recentObjectIds(): number[] {
    return this._sessionService.recentObjectIds();
  }

  public getNavigation(object: any, project: any): { path: any[]; queryParams?: any } {
    // const codeIdentifier = this.getObjectIdentifierCode(project, object);
    const path = [];
    const queryParams = {};

    // switch (object.class) {
    //   case ObjectClass.Project:
    //     path = ['/projects', object.id, 'overview', 'settings'];
    //     break;
    //   case ObjectClass.Plan:
    //     path = ['/projects', object.projectId, 'plans', object.id];
    //     break;
    //   case ObjectClass.Scenario:
    //     path = ['/projects', object.projectId, 'scenarios', object.id];
    //     break;
    //   case ObjectClass.Doc:
    //     path = ['/projects', object.projectId, 'docs'];
    //     queryParams = { object: codeIdentifier };
    //     break;
    //   case ObjectClass.Task:
    //     path = ['/projects', object.projectId, 'tasks'];
    //     queryParams = { object: codeIdentifier };
    //     break;
    //   case ObjectClass.AssetImage:
    //     path = ['/projects', object.projectId, 'images'];
    //     queryParams = { object: codeIdentifier };
    //     break;
    //   case ObjectClass.Component:
    //     path = [
    //       '/projects',
    //       object.projectId,
    //       'plans',
    //       object.planId,
    //       'components',
    //     ];
    //     queryParams = { object: codeIdentifier };
    //     break;
    //   case ObjectClass.DataObjectTable:
    //     return {
    //       path: [
    //         '/projects',
    //         object.projectId,
    //         'data',
    //         object.meta ? object.meta.dataId : object.dataId,
    //       ],
    //       queryParams: { object: codeIdentifier },
    //     };
    //     break;
    // }

    return { path, queryParams };
  }

  public url(object: any, project: any): string {
    const navigation = this.getNavigation(object, project);

    const url = new URL(document.location.origin as any);
    url.pathname = navigation.path.join('/');

    Object.keys(navigation.queryParams).forEach((name) => {
      const value = navigation.queryParams[name];
      url.searchParams.set(name, value);
    });

    return url.toString();
  }


  public windowOpen(object: IObject, project: Project): void {
    (window as any).open(this.url(object, project));
  }

  public navigate(object: any, project: any, updateRecent: boolean = true): void {
    // let codeIdentifier = '';
    // switch (object.class) {
    //   case ObjectClass.Project:
    //   case ObjectClass.Plan:
    //   case ObjectClass.Scenario:
    //     const navigation = this.getNavigation(object, project);
    //     this._router.navigate(navigation.path, { queryParams: navigation.queryParams });
    //     break;
    //   case ObjectClass.Doc:
    //   case ObjectClass.Task:
    //   case ObjectClass.AssetImage:
    //   case ObjectClass.DataObjectTable:
    //   case ObjectClass.Component:
    //     codeIdentifier = this.getObjectIdentifierCode(project, object);
    //     this._router.navigate(
    //       [],
    //       { relativeTo: this._route, queryParams: { object: codeIdentifier }, queryParamsHandling: 'merge' },
    //     );
    //     break;
    // }

    if (updateRecent) {
      this._pushRecentId(object.id);
    }
  }

  public objectIdentifier(project: Project, object: any): any {
    let projectAbr = '';
    let objectAbr = '';
    let objectNumber = '';

    if (project) {
      projectAbr = project.abr;
      if (project.meta && project.meta.abr) {
        projectAbr = project.meta.abr;
      }
    }

    if (object) {
      const cls = ObjectClasses.find((item) => {
        return item.value === object.class || item.value === `${item.parentClass}_${object.class}`;
      });

      objectAbr = cls ? cls.abr : '';

      objectNumber = object.number;

      if (object.meta && object.meta.number) {
        objectNumber = object.meta.number;
      }
    }

    return { projectAbr, objectAbr, objectNumber };
  }

  public getObjectIdentifierCode(project: Project, object: IObject): string {
    if (object.identifier) {
      // SP-T1970 Identifiers in objects table are not full. We can't use this values for links
      // In this case skip value from the BE and build indetifier on FE side
      const validIdentifier = !!object.identifier.match(/\-/);
      if (validIdentifier) {
        return object.identifier;
      }
    }

    const identifier = this.objectIdentifier(project, object);

    if (identifier.objectAbr && identifier.objectNumber) {
      return `${identifier.projectAbr}-${identifier.objectAbr}${identifier.objectNumber}`;
    }

    return identifier.projectAbr;
  }

  private _pushRecentId(id: number): void {
    const data = this._sessionService.recentObjectIds() || [];

    const index = data.indexOf(id);
    if (index > -1) {
      data.splice(index, 1);
    }

    data.unshift(id);

    if (data.length > this.maxRecentCount) {
      data.splice(this.maxRecentCount, data.length);
    }

    this._sessionService.recentObjectIds(data);
  }

}
