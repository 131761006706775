import { RoundingMode } from '../enums';


export const RoundingModesCheckIn = [
  { name: 'Nearest', value: RoundingMode.Nearest },
  { name: 'Round up (reduces duration)', value: RoundingMode.Up },
  { name: 'Round down (increases duration)', value: RoundingMode.Down },
];

export const RoundingModesCheckOut = [
  { name: 'Nearest', value: RoundingMode.Nearest },
  { name: 'Round up (increases duration)', value: RoundingMode.Up },
  { name: 'Round down (reduces duration)', value: RoundingMode.Down },
];
