import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class AppService {

  public settings: any = {};

  constructor(
    private _http: HttpClient,
  ) { }

  public load(): Observable<void> {
    return this._http.get<any>('api/app/config')
      .pipe(
        tap((response) => {
          this.settings = response.data;
        }),
      );
  }

  public init(): void {
    const link = window.document.querySelector('link[rel="icon"]');
    if (link) {
      link.setAttribute('href', this.settings.brandFavIcon);
    }

    this.setStyle('--brand-nav-background-color', this.settings.brandNavBackgroundColor);
    this.setStyle('--brand-link-color', this.settings.brandLinkColor);
    this.setStyle('--brand-primary-color-rgb', this.settings.brandPrimaryColorRgb);
    this.setStyle('--brand-primary-color', this.settings.brandPrimaryColor);
    this.setImageStyle('--brand-dark-logo', this.settings.brandDarkLogo);
    this.setImageStyle('--brand-light-logo', this.settings.brandLightLogo);
    this.setImageStyle('--brand-background', this.settings.brandBackground);
    this.setStyle('--webkit-scrollbar-width', this.settings.scrollBarWidth);
    this.setStyle('--webkit-scrollbar-color', this.settings.scrollBarColor);
  }

  public setStyle(name: string, value: string): void {
    if (value) {
      document.documentElement.style.setProperty(name, value);
    }
  }

  public setImageStyle(name: string, value: string): void {
    if (value) {
      this.setStyle(name, `url(${value})`);
    }
  }

}
