import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';
import { FsTransferService } from '@firestitch/transfer';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class FieldData {

  constructor(
    private _api: FsApi,
    private _transfer: FsTransferService,
  ) { }

  public create(data = { id: null }) {
    return data;
  }

  public updateConfig(objectId: number, data: {}): Observable<any> {
    return this._api.put(`fields/${objectId}`, data, { key: 'fields' });
  }

  public getConfig(objectId: number, data: any = null): Observable<any> {
    return this._api.get(`fields/${objectId}`, data, { key: 'fields' });
  }

  public updateValues(
    configObjectID: number,
    valueObjectID: number,
    data: any = {},
  ): Observable<any> {
    return this._api
      .post(`fields/${configObjectID}/values/${valueObjectID}`, data, { key: 'fields' });
  }

  public filesPost(
    configObjectID: number,
    valueObjectID: number,
    fieldGuid: string,
    data: any = {},
    config: RequestConfig = {}): Observable<any> {
    const requestConfig = { key: '', ...config };

    return this._api
      .post(`fields/${configObjectID}/files/${valueObjectID}/${fieldGuid}`, data, requestConfig);
  }

  public filesDelete(
    configObjectID: number,
    valueObjectID: number,
    fieldGuid: string,
    data: any = {},
    config: RequestConfig = {}): Observable<any> {
    const requestConfig = { key: '', ...config };

    return this._api
      .delete(`fields/${configObjectID}/files/${valueObjectID}/${fieldGuid}`, data, requestConfig);
  }

  public download(
    configObjectID: number,
    valueObjectID: number,
    fieldGuid: string,
    data: any = {},
  ): void {
    this._transfer
      .post(`api/fields/${configObjectID}/files/${valueObjectID}/${fieldGuid}/download`, data);
  }

  public getValues(
    configObjectID: number,
    valueObjectID: number,
    versionId: number = null,
    data: any = {},
    config: any = { key: 'fields' },
  ): Observable<any> {
    return this._api.get(`fields/${configObjectID}/values/${valueObjectID}${versionId ? `/${versionId}` : ''}`, data, config);
  }

  public putFieldAction(objectId: number, field, action, data): Observable<any> {
    return this._api.put(`fields/${objectId}/actions`, {
      action,
      data,
      field,
    }, { key: null });
  }
}
