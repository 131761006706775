import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';
import { Block } from '@firestitch/block-editor';
import { FsTransferService } from '@firestitch/transfer';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class PdfData<T = any> {

  constructor(
    private _api: FsApi,
    private _transfer: FsTransferService,
  ) { }

  public get(id: number, query: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.get(
      `pdfs/${id}`,
      query,
      {
        key: 'pdf',
        ...config,
      },
    );
  }

  public gets(data: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.request(
      'GET',
      'pdfs',
      data,
      {
        key: 'pdfs',
        ...config,
      },
    );
  }

  public put(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.put(
      `pdfs/${data.id}`,
      data,
      {
        key: 'pdf',
        ...config,
      },
    );
  }

  public post(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      'pdfs',
      data,
      {
        key: 'pdf',
        ...config,
      },
    );
  }

  public delete(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.delete(
      `pdfs/${data.id}`,
      data,
      {
        key: 'pdf',
        ...config,
      },
    );
  }

  public upload(file, config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      'pdfs/upload',
      { file },
      {
        key: 'pdf',
        ...config,
      },
    );
  }

  public downloadPdf(pdfId: number, config: RequestConfig = {}) {
    this._transfer.post(`pdfs/${pdfId}/download/pdf`);
  }

  public downloadHtml(pdfId: number, config: RequestConfig = {}) {
    this._transfer.post(`pdfs/${pdfId}/download/html`);
  }

  public save(data: any): Observable<T> {
    return (data.id)
      ? this.put(data)
      : this.post(data);
  }

  public pagesGet(pdfId: number, pdfPageId: any, data: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.request(
      'GET',
      `pdfs/${pdfId}/pages/${pdfPageId}`,
      data,
      {
        key: 'pdfPage',
        ...config,
      },
    );
  }

  public pagesGets(pdfId: number, data: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.request(
      'GET',
      `pdfs/${pdfId}/pages`,
      data,
      {
        key: 'pdfPages',
        ...config,
      },
    );
  }

  public pagesPut(pdfId: number, data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.put(
      `pdfs/${pdfId}/pages/${data.id}`,
      data,
      {
        key: 'pdfPage',
        ...config,
      },
    );
  }

  public pagesPost(pdfId: number, data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      `pdfs/${pdfId}/pages`,
      data,
      {
        key: 'pdfPage',
        ...config,
      },
    );
  }

  public pagesDelete(pdfId: number, data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.delete(
      `pdfs/${pdfId}/pages/${data.id}`,
      data,
      {
        key: 'pdfPage',
        ...config,
      },
    );
  }

  public pagesDownloadPdf(pdfId: number, data: any, config: RequestConfig = {}) {
    this._transfer.post(`pdfs/${pdfId}/pages/${data.id}/download/pdf`);
  }

  public pagesDownloadHtml(pdfId: number, data: any, config: RequestConfig = {}) {
    this._transfer.post(`pdfs/${pdfId}/pages/${data.id}/download/html`);
  }

  public pagesSave(pdfId: number, data: any): Observable<T> {
    return (data.id)
      ? this.pagesPut(pdfId, data)
      : this.pagesPost(pdfId, data);
  }

  public pagesContentSampleGet(
    pdfId: number,
    pdfPageId: any,
    config: RequestConfig = {},
  ): Observable<T> {
    return this._api.request(
      'GET',
      `pdfs/${pdfId}/pages/${pdfPageId}/content`,
      {},
      {
        key: 'content',
        ...config,
      },
    );
  }

  public blocksGets(pdfId: number, pdfPageId: number, data: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.request(
      'GET',
      `pdfs/${pdfId}/pages/${pdfPageId}/blocks`,
      data,
      {
        key: 'pdfBlocks',
        ...config,
      },
    );
  }

  public blocksPut(pdfId: number, pdfPageId: number, data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.put(
      `pdfs/${pdfId}/pages/${pdfPageId}/blocks/${data.id}`,
      data,
      {
        key: 'pdfBlock',
        ...config,
      },
    );
  }

  public blocksPost(pdfId: number, pdfPageId: number, data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      `pdfs/${pdfId}/pages/${pdfPageId}/blocks`,
      data,
      {
        key: 'pdfBlock',
        ...config,
      },
    );
  }

  public blocksDelete(pdfId: number, pdfPageId: number, pdfBlockId: number, config: RequestConfig = {}): Observable<T> {
    return this._api.delete(
      `pdfs/${pdfId}/pages/${pdfPageId}/blocks/${pdfBlockId}`,
      {},
      {
        key: 'pdfBlock',
        ...config,
      },
    );
  }

  public blocksReorder(pdfId: number, pdfPageId: number, blocks: Block[], config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      `pdfs/${pdfId}/pages/${pdfPageId}/blocks/reorder`,
      {
        blocks,
      },
      {
        key: 'pdfBlock',
        ...config,
      },
    );
  }

  public blocksSave(pdfId: number, pdfPageId: number, data: any): Observable<T> {
    return (data.id)
      ? this.blocksPut(pdfId, pdfPageId, data)
      : this.blocksPost(pdfId, pdfPageId, data);
  }

}
