import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { AppService } from '@common/services';


@Component({
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent implements OnInit {

  public additionalLogo: string;

  constructor(
    private _appService: AppService,
  ) { }

  public ngOnInit(): void {
    this.additionalLogo = this._appService.settings.additionalLogo;
  }

}
