import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { FsBuildService } from '@firestitch/build';

import { AppService } from '@common/services';

import { SessionService, WindowMessageService } from './core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {

  // Timer service injected here to construct service on app init. Don't remove
  // Global Drawer service injected here to construct service on app init. Don't remove
  constructor(
    private _build: FsBuildService,
    private _windowMessageService: WindowMessageService,
    private _appService: AppService,
    private _sessionService: SessionService,
  ) {
    this._appService.init();
    this._windowMessageService.init();
  }

}
