import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { merge, Observable, Subject, forkJoin } from 'rxjs';
import { filter, map, takeUntil, distinctUntilChanged, tap } from 'rxjs/operators';

import { last, remove } from 'lodash-es';

import { ShiftAssignmentDrawer } from '@libs/drawers/models/shift-assignment-drawer.model';
import { Drawer } from '@libs/drawers/models';

import { IObject, ObjectClass, ObjectClasses, EventEmitterType } from '@app/common';
import { EventEmitterEvent } from '@app/common/interfaces';
import { EventEmitterService } from '@app/core';

import { IDrawerChange } from '../interfaces/drawer-change.interface';


@Injectable()
export class ObjectDrawerService implements OnDestroy {

  private _drawerChangesStream$ = new Subject();
  private _destroy$ = new Subject();
  private _drawers: Drawer[] = [];

  private _activeObjectIdentifier: string = null;

  constructor(
    private _router: Router,
    private _injector: Injector,
    private _route: ActivatedRoute,
    private _location: Location,
    private _eventService: EventEmitterService,
  ) {
    this._router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        return this._route.snapshot.queryParams.object;
      }),
      filter((objectIdentifier: string) =>  objectIdentifier !== this._activeObjectIdentifier),
      distinctUntilChanged(),
    ).subscribe((objectIdentifier) => {
      this.closeAll();

      if (objectIdentifier) {
        this.openObjectIdentifier(objectIdentifier);
      }
    });

    this._eventService.event$
      .pipe(
        filter((event) => (event.type === EventEmitterType.ObjectDrawerOpen)),
        takeUntil(this._destroy$),
      )
      .subscribe((event: EventEmitterEvent) => {
        this.openObject(event.data.object, event.data.options || {});
      });
  }

  public get drawers(): Drawer[] {
    return this._drawers;
  }

  public openObjectIdentifier(
    objectIdentifier: string,
    options: { closeAll?: boolean } = {},
  ): Drawer {
    const drawer = this.create(objectIdentifier);

    if (!drawer) {
      return null;
    }

    drawer.openObjectIdentifier(objectIdentifier);
    this._registerDrawer(objectIdentifier, drawer, options);
    this._listenDrawerChanges(drawer, objectIdentifier);

    return drawer;
  }

  // public openProjectObject(object: IObject, options: { closeAll?: boolean } = {}): Drawer {
  //   const drawer = this.create(object.identifier);
  //   drawer.openObject(object);
  //   this._registerDrawer(object.identifier, drawer, options);

  //   return drawer;
  // }

  public openObject(object: IObject, options: { closeAll?: boolean } = { closeAll: false }): Drawer {
    const drawer = this.create(object.identifier);

    drawer.openObject(object);
    this._registerDrawer(object.identifier, drawer, options);

    return drawer;
  }

  public getDrawerChanges(cls: ObjectClass): Observable<IDrawerChange> {
    return this._drawerChangesStream$
      .pipe(
        filter((event: any) => {
          return event.class === cls;
        }),
        map((event: any) => event.data),
        map((data) => {
          switch (cls) {
            case ObjectClass.Shift: {
              return { type: data.type, data: data.shift };
            }

            // case ObjectClass.Doc: {
            //   return { type: data.type, data: data.doc };
            // }

            // case ObjectClass.Component: {
            //   return { type: data.type, data: data.component };
            // }
          }
        }),
        takeUntil(this._destroy$),
      );
  }

  public create(
    objectIdentifier: string,
  ): ShiftAssignmentDrawer {
    const cls = this._getIdentifierClass(objectIdentifier);

    switch (cls) {
      case ObjectClass.Shift: {
        return new ShiftAssignmentDrawer(this._injector);
      }
    }
  }

  public closeAll(): void {
    const drawers = this._drawers;
    this._drawers = [];
    drawers.forEach((drawer) => {
      drawer.close();
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this.closeAll();
  }

  private _getIdentifierClass(object): string {
    const match = object.match(/-([\w])(\d+)$/) || [];
    const objectClass = ObjectClasses.find((item) => {
      return item.abr === match[1];
    });

    return objectClass ? objectClass.value : null;
  }

  private _listenDrawerChanges(drawer, objectIdentifier): void {
    const cls = this._getIdentifierClass(objectIdentifier);
    const changed$ = this._getDrawerChangeListener(drawer);
    const deleted$ = this._getDrawerDeleteListener(drawer, cls);

    merge(changed$, deleted$)
      .subscribe({
        next: (data) => {
          this._drawerChangesStream$.next({
            class: cls,
            data,
          });
        },
      });
  }

  private _registerDrawer(
    objectIdentifier: string,
    drawer: Drawer,
    options: { closeAll?: boolean } = {},
  ): Drawer {
    if (options.closeAll || options.closeAll === undefined) {
      this.closeAll();
    }

    this._activeObjectIdentifier = objectIdentifier;
    this._replaceUrl(objectIdentifier);
    this._drawers.push(drawer);

    drawer.afterClosed$
      .subscribe(() => {
        const item = last(this._drawers);

        this._replaceUrl(null);

        remove(this._drawers, drawer);

        drawer.destroy();
      });

    return drawer;
  }


  private _replaceUrl(object): void {
    this._router.navigate(
      [],
      {
        relativeTo: this._route,
        queryParams: { object },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      },
    );
  }

  private _getDrawerChangeListener(drawer: any): Observable<any> {
    return drawer.dataChanged$.pipe(
      map((data: any) => {
        return { type: 'changed', ...data };
      }),
    );
  }

  private _getDrawerDeleteListener(drawer: Drawer, cls: string): Observable<any> {
    return drawer.deleted$
      .pipe(
        map((data) => {
          const payload: any = {};

          switch (cls) {
            case ObjectClass.Shift: {
              payload.shift = data;
            } break;

            // case ObjectClass.Doc: {
            //   payload.doc = data;
            // } break;

            // case ObjectClass.Component: {
            //   payload.component = data;
            // } break;
          }

          return { type: 'deleted', ...payload };
        }),
      );
  }

}
