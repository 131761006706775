import { Injector, Component } from '@angular/core';

import { FsStore } from '@firestitch/store';
import { FsMessage } from '@firestitch/message';
import { DrawerRef, FsDrawerService } from '@firestitch/drawer';

import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

import { ObjectService } from '@app/core';
import { FsClipboard } from '@firestitch/clipboard';


export abstract class Drawer {

  protected readonly _drawerService: FsDrawerService;
  protected readonly _destroy$ = new Subject();
  protected readonly _deleted$ = new Subject<any>();
  protected _message: FsMessage;

  private _drawerRef: DrawerRef<any> = null;
  private _store: FsStore;
  private _injector: Injector;
  private _clipboard: FsClipboard;
  private _objectService: ObjectService;

  constructor(injector: Injector) {
    this._injector = injector;
    this._drawerService = this.injector.get(FsDrawerService);
    this._store = injector.get(FsStore);
    this._message = injector.get(FsMessage);
    this._clipboard = injector.get(FsClipboard);
    this._objectService = injector.get(ObjectService);
  }

  public abstract openObjectIdentifier(objectIdentifier: string): void;

  public close(): void {
    this.drawerRef.close();
  }

  public get destroy$(): Observable<any> {
    return this._destroy$
      .pipe(
        takeUntil(this._destroy$),
      );
  }

  public get dataChanged$(): Observable<any> {
    return this._drawerRef.dataChanged$
      .pipe(
        takeUntil(this._destroy$),
      );
  }

  public set drawerRef(drawerRef: DrawerRef<Component>) {
    this._drawerRef = drawerRef;
  }

  public get drawerRef(): DrawerRef<Component> {
    return this._drawerRef;
  }

  public dataChanged(): Observable<any> {
    return this.dataChanged$
      .pipe(
        takeUntil(this._destroy$),
      );
  }

  get afterClosed$(): Observable<any> {
    return this.drawerRef.afterClosed$
      .pipe(
        takeUntil(this._destroy$),
      );
  }

  get deleted$(): Observable<any> {
    return this._deleted$
      .pipe(
        takeUntil(this._destroy$),
      );
  }

  get injector(): Injector {
    return this._injector;
  }

  public destroy(): void {
    this._deleted$.complete();

    this._destroy$.next();
    this._destroy$.complete();
  }

  protected copyOrOpenLink(event: any, object: any, project: any): void {
    const url = this._objectService.url(object, project);

    if (event.event.shiftKey) {
      (<any>window).open(url);
    } else {
      this._clipboard.copy(url);
      this._message.success('Copied to Clipboard');
    }
  }

}
