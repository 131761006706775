import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class ContentData<T = any> {

  constructor(private _api: FsApi) { }

  public get(id: number, query: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.get(
      `contents/${id}`,
      query,
      {
        key: 'content',
        ...config,
      },
    );
  }

  public gets(query: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.request(
      'GET',
      'contents',
      query,
      {
        key: 'contents',
        ...config,
      },
    );
  }

  public put(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.put(
      `contents/${data.id}`,
      data,
      {
        key: 'content',
        ...config,
      },
    );
  }

  public post(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      'contents',
      data,
      {
        key: 'content',
        ...config,
      },
    );
  }

  public delete(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.delete(
      `contents/${data.id}`,
      data,
      {
        key: 'content',
        ...config,
      },
    );
  }

  public preview(id: number, query: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.get(
      `contents/${id}/preview`,
      query,
      {
        key: '',
        ...config,
      },
    );
  }

  public previewSubmit(id: number, data: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      `contents/${id}/preview/submit`,
      data,
      {
        key: 'contentResultObject',
        ...config,
      },
    );
  }

  public previewResult(id: number, data: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      `contents/${id}/preview/result`,
      data,
      {
        key: 'contentResultObject',
        ...config,
      },
    );
  }

  public save(data: any): Observable<T> {
    return (data.id)
      ? this.put(data)
      : this.post(data);
  }

  public order(data): Observable<any> {
    return this._api.put('contents/order', data, { key: null });
  }

}
