import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { FsAutocompleteChipsModule } from '@firestitch/autocomplete-chips';

import { StatusMenuComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    FsAutocompleteChipsModule,
  ],
  declarations: [
    StatusMenuComponent,
  ],
  exports: [
    StatusMenuComponent,
  ],
})
export class StatusMenuModule {}
