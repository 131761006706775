export function getChangedFields(newObject, originObject): { [key: string]: any } {
  const result: { [key: string]: any } = {};

  // Create Mode
  if (!originObject) {
    return newObject;
  }

  Object.keys(newObject)
    .forEach((key) => {
      switch (typeof newObject[key]) {
        case 'number':
        case 'string':
          if (newObject[key] !== originObject[key]) {
            result[key] = newObject[key];
          }
          break;
        default:
          if (JSON.stringify(newObject[key]) !== JSON.stringify(originObject[key])) {
            result[key] = newObject[key];
          }
      }
    });

  return result;
}
