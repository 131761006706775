import { Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { tap } from 'rxjs/operators';

import { EnvironmentData, SessionService } from '@app/core';
import { Environment } from '@app/common/interfaces';


@Component({
  templateUrl: './workspace-create.component.html',
  styleUrls: ['./workspace-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceCreateComponent {

  public environment: Environment = { id: null };

  constructor(
    private _dialogRef: MatDialogRef<WorkspaceCreateComponent>,
    private _environmentData: EnvironmentData,
    private _sessionService: SessionService,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) private data,
  ) {
    this.environment = { ...data.environment };
  }

  public save = () => {

    const account = this._sessionService.account();

    return this._environmentData.save(this.environment, { key: null })
      .pipe(
        tap(({ environment, permissions }) => {
          this._sessionService.permissions(permissions);
          this.close(environment);
          this._environmentData.switch(account.id, environment.id).subscribe(
            (response) => {
              this._sessionService.environment(environment);
              this._dialogRef.close();
              this._router.navigate(['blank']).then(() => {
                this._router.navigateByUrl('/projects');
              });
            },
          );
        }),
      );
  };

  public close(data = null) {
    this._dialogRef.close(data);
  }

}
