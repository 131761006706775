import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';
import { parse } from '@firestitch/date';

import { Observable } from 'rxjs';

import { IAvailability } from '@app/common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class AvailabilityData {

  constructor(private _fsApi: FsApi) { }

  public create(data: IAvailability = { id: null }): IAvailability {
    data.startDate = data.startDate ? parse(data.startDate) : null;
    data.endDate = data.startDate ? parse(data.endDate) : null;

    return data;
  }

  public get(id: number, query: any = {}): Observable<any> {
    return this._fsApi.get(`availabilities/${id}`, query, { key: 'availability' });
  }

  public gets(query = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', 'availabilities', query, { key: 'availabilities', ...config });
  }

  public put(data, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.put(`availabilities/${data.id}`, data, { key: 'availability', ...config });
  }

  public post(data): Observable<any> {
    return this._fsApi.post('availabilities', data, { key: 'availability' });
  }

  public delete(data): Observable<any> {
    return this._fsApi.delete(`availabilities/${data.id}`, data, { key: 'availability' });
  }

  public deleteDay(accountId: number, date: Date, data: any = {}): Observable<any> {
    data.accountId = accountId;
    data.date = date;
    return this._fsApi.delete('availabilities/clearday', data, { key: 'availability' });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }

    return this.post(data);
  }

}
