import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class SettingData {

  constructor(private _api: FsApi) { }

  public get(name: any = {}, config: RequestConfig = {}): Observable<any> {
    return this._api.get('setting', { name }, { key: 'setting', ...config });
  }

}
