<mat-toolbar color="primary">
  <div class="row">
    <a fsNavBack><mat-icon class="back-icon">keyboard_arrow_left</mat-icon></a>
    <button
      mat-icon-button
      class="menu"
      (click)="sideNavClick()"
      *ngIf="account">
      <mat-icon>menu</mat-icon>
    </button>
    <div fsNavComponent="image" type="image"></div>
    <div>
      <div fsNavSupertitle></div>
      <div fsNavTitle></div>
    </div>
  </div>
</mat-toolbar>
