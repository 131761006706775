import { Injectable } from '@angular/core';

import { FsTransferService } from '@firestitch/transfer';
import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ObjectData {

  constructor(
    private _api: FsApi,
    private _transfer: FsTransferService,
  ) { }

  public get(objectId, query = {}): Observable<any> {
    return this._api.get(`objects/${objectId}`, query, { key: 'object' });
  }

  public gets(data = {}, config: RequestConfig = {}): Observable<any> {
    return this._api.request('GET', 'objects', data, { key: 'objects', ...config });
  }

  public highlight(objectId: number, data): Observable<any> {
    return this._api.post(`objects/${objectId}/highlight`, data, { key: null });
  }

  public unhighlight(objectId: number, data): Observable<any> {
    return this._api.delete(`objects/${objectId}/highlight`, data, { key: null });
  }

  public relate(objectId: number, relativeObjectID: number) {
    return this._api.post(`objects/${objectId}/relate`, { objectId: relativeObjectID }, { key: 'objects' });
  }

  public removeRelative(objectId: number, relativeObjectID: number) {
    return this._api.delete(`objects/${objectId}/relate`, { objectId: relativeObjectID });
  }

  public getVersion(objectId, objectVersionId, query = {}): Observable<any> {
    return this._api.get(`objects/${objectId}/versions/${objectVersionId}`, query, { key: 'objectVersion' });
  }

  public getVersions(objectId, data = {}, config: RequestConfig = {}): Observable<any> {
    return this._api.request('GET', `objects/${objectId}/versions`, data, { key: 'objectVersions', ...config });
  }

  public restoreVersion(objectId: number, objectVersionId: number) {
    return this._api.post(`objects/${objectId}/versions/${objectVersionId}/restore`, null, { key: 'objectVersion' });
  }

  // public filesPost(objectId: number, data): Observable<any> {
  //   return this._api.post(`objects/${objectId}/files`, data, { key: 'file' });
  // }

  // public filesDelete(objectId: number, file: any): Observable<any> {
  //   return this._api.delete(`objects/${objectId}/files/${file.id}`, {}, { key: 'file' });
  // }

  // public filesDownload(objectId, file): void {
  //   this._transfer.post(`objects/${objectId}/files/${file.id}/download`);
  // }

  // public filesGets(objectId: number, data: any = {}, config = {}): Observable<any> {
  //   return this._api.get(`objects/${objectId}/files`, data, { key: 'files', ...config });
  // }

  public configsGets(objectId: number, names: string[] = []): Observable<any> {
    return this._api.get(`objects/${objectId}/configs`, { names }, { key: 'configs' });
  }

  public configsPost(objectId: number, configs: any): Observable<any> {
    return this._api.post(`objects/${objectId}/configs`, { configs }, { key: 'configs' });
  }

  public avatarImage(objectId, file): Observable<any> {
    return this._api.post(`objects/${objectId}/avatar/image`, { file }, { key: 'avatar' });
  }

}
