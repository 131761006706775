import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';

import { Status } from '@app/common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class StatusData {

  constructor(private _fsApi: FsApi) { }

  public create(data: Status = { id: null }): Status {
    data.class = data.class || 'task';

    return data;
  }

  public get(id, query = {}): Observable<any> {
    return this._fsApi.get(`statuses/${id}`, query, { key: 'status' });
  }

  public gets(data = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', 'statuses', data, { key: 'statuses', ...config });
  }

  public put(data, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.put(`statuses/${data.id}`, data, { key: 'status', ...config });
  }

  public post(data): Observable<any> {
    return this._fsApi.post('statuses', data, { key: 'status' });
  }

  public delete(data): Observable<any> {
    return this._fsApi.delete(`statuses/${data.id}`, data, { key: 'status' });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }

    return this.post(data);
  }

  public order(data): Observable<any> {
    return this._fsApi.put('statuses/order', data, { key: null });
  }

  public setDefault(statusId: number, data = {}): Observable<any> {
    return this._fsApi.put(`statuses/${statusId}/default`, data, { key: 'status' });
  }
}
