import { Injectable, Injector, OnDestroy } from '@angular/core';

import { FsStore } from '@firestitch/store';

import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountData } from '@common/data';
import { AccountType } from '@common/enums';
import { Account } from '@common/interfaces';

import { Environment } from '@app/common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class SessionService implements OnDestroy {

  private _destroy$ = new Subject();

  constructor(
    private _store: FsStore,
    private _injector: Injector,
  ) { }

  public set(data: any): void {
    this._store.set('account', data.account);
    this._store.set('permissions', data.permissions);
    this._store.set('environment', data.environment);
    this._store.set('force2fa', data.force2fa || false);
  }

  public destroy(): void {
    this._store.clear();
  }

  public recentObjectIds(data: number[] = null): number[] {
    if (data) {
      this._store.set('recentObjectIds', data);
    }
    const recentObjectIds = this._store.get('recentObjectIds');

    return recentObjectIds ? recentObjectIds : [];
  }

  public get account$(): Observable<Account> {
    return this._store.observe('account')
      .pipe(
        map((response) => response.value),
      );
  }

  public account(data: Account = null): Account {
    if (data) {
      this._store.set('account', data);
    }

    return this._store.get('account');
  }

  public accountId(): number {
    return (this.account() || {}).id;
  }

  public get force2fa(): boolean {
    return this._store.get('force2fa');
  }

  public clearForce2fa() {
    this._store.remove('force2fa');
  }

  public accountEmail(): Observable<string> {
    return this._injector.get(AccountData)
      .get(this.accountId())
      .pipe(
        map((account) => (account.email)),
      );
  }

  public accountExists(): boolean {
    return !!this.account();
  }

  public isTempStaff() {
    return this.account().type === AccountType.FieldStaff ||
      this.account().type === AccountType.OutsourcedStaff ||
      this.account().type === AccountType.Candidate;
  }

  public isOfficeStaff() {
    return this.account().type === AccountType.OfficeStaff;
  }

  public isSessionAccount(account: Account): boolean {
    return account && this.accountId() === account.id;
  }

  public environment(data: Environment = null): Environment {
    if (data) {
      this._store.set('environment', data);
    }

    return this._store.get('environment');
  }

  public filterPersist(data = null): any {
    if (data) {
      this._store.set('fs-filter-persist', data);
    }

    return this._store.get('fs-filter-persist');
  }

  public permissions(data = null): any {
    if (data) {
      this._store.set('permissions', data);
    }

    return this._store.get('permissions');
  }

  public environmentId(): number {
    return this.environment() ? this.environment().id : null;
  }

  public clear(): void {
    this._store.clear();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
