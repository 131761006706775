import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { range } from 'lodash-es';


@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressComponent implements OnInit {

  @Input() public step = 0;

  @Input() public set steps(value: number) {
    this.stepsData = range(value);
  }

  public stepsData = range(3);

  constructor() { }

  public ngOnInit(): void {
  }

}
