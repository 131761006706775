import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';
import { FsTransferService } from '@firestitch/transfer';

import { Observable } from 'rxjs';

import { AccountApplicableTaxForm } from '@common/enums';
import { Account } from '@common/interfaces';

import { AccountChequeMethod } from '../enums';


@Injectable({
  providedIn: 'root',
})
export class AccountData {

  constructor(
    private _api: FsApi,
    private _transfer: FsTransferService,
  ) { }

  public create(data: Account = { id: null }) {
    data.avatar = data.avatar || {};
    data.address = data.address || {};
    data.phone = data.phone || null;
    if (data.phoneMobile && !(
      data.phoneMobile.includes('-') || data.phoneMobile.includes('(') || data.phoneMobile.includes(')')
    )) {
      data.phoneMobile = data.phoneMobile.replace(/^1/, '');

    }
    data.divisions = data.divisions || [];
    data.skills = data.skills || [];
    data.payoutAccountsConfigured = data.payoutAccountsConfigured || [];
    data.applicableTaxForm = data.applicableTaxForm || AccountApplicableTaxForm.T4;
    data.chequeMethod = data.chequeMethod || AccountChequeMethod.DeliveryPickup;

    data.aclEntries = data.aclEntries || [];
    data.clientRoles = [];
    const clients: { [key: number]: any } = {};
    data.aclEntries
      .forEach((aclEntry: any) => {
        if (!aclEntry.objectId) {
          return;
        }
        if (!clients[aclEntry.objectId]) {
          clients[aclEntry.objectId] = aclEntry.object;
          clients[aclEntry.objectId].aclRoles = [];
        }

        clients[aclEntry.objectId].aclRoles.push(aclEntry.aclRole);

      });
    data.clientRoles = Object.values(clients)
      .map((client) => {
        client.aclEntries = data.aclEntries
          .filter((aclEntry) => aclEntry.objectId === client.id)
          // Nested client object not needed and recursively has link on itself.
          // Remove to avoid "maximum stack size exceeded"
          .map((aclEntry) => ({ ...aclEntry, object: null }));

        return client;
      });

    return data;
  }

  public get(accountId, data: any = {}, config: any = {}): Observable<any> {
    return this._api.get(`accounts/${accountId}`, data, { key: 'account', ...config });
  }

  public gets(data = {}, config: RequestConfig = {}): Observable<any> {
    return this._api.request('GET', 'accounts', data, { key: 'accounts', ...config });
  }

  public put(account, config: RequestConfig = {}): Observable<any> {
    return this._api.put(`accounts/${account.id}`, account, { key: 'account', ...config });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }

    return this.post(data);
  }

  public post(account): Observable<any> {
    return this._api.post('accounts', this.create(account), { key: 'account' });
  }

  public requestchange(id: number, data: any): Observable<any> {
    return this._api.post(`accounts/${id}/requestchange`, data, { key: null });
  }

  public availabilitycheck(checks): Observable<any> {
    return this._api.post('accounts/availabilitycheck', { checks }, { key: 'checks' });
  }

  public emailcheck(email: string, data: any = {}): Observable<any> {
    return this._api.post('accounts/emailcheck', { email, ...data }, { key: 'inUse' });
  }

  public delete(account): Observable<any> {
    return this._api.delete(`accounts/${account.id}`, account, { key: 'account' });
  }

  public image(account, file): Observable<any> {
    return this._api.post(`accounts/${account.id}/image`, { file }, { key: 'account' });
  }

  public putPasswordChange(account, config: RequestConfig = {}): Observable<any> {
    return this._api.put(`accounts/${account.id}/password/change`, account, { key: 'account', ...config });
  }

  public putPasswordReset(account, config: RequestConfig = {}): Observable<any> {
    return this._api.put(`accounts/${account.id}/password/reset`, account, { key: 'account', ...config });
  }

  public activate(accountId, data: any = {}): Observable<any> {
    return this._api.post(`accounts/${accountId}/activation/activate`, data, { key: 'account' });
  }

  public emailActivation(accountId, data: any = {}): Observable<any> {
    return this._api.post(`accounts/${accountId}/activation/email`, data, { key: 'account' });
  }

  public undelete(accountId, data: any = {}): Observable<any> {
    return this._api.post(`accounts/${accountId}/undelete`, data, { key: 'account' });
  }

  public getEnvironments(accountId: number, data = {}): Observable<any> {
    return this._api.request('GET', `accounts/${accountId}/environments`, data, { key: 'environments' });
  }

  public postDivisions(accountId: number, divisionIds = {}): Observable<any> {
    return this._api.post(`accounts/${accountId}/divisions`, { divisionIds }, { key: 'account' });
  }

  public postSkills(accountId: number, skillIds = {}): Observable<any> {
    return this._api.post(`accounts/${accountId}/skills`, { skillIds }, { key: 'account' });
  }

  public workPermitDownload(accountId: number) {
    this._transfer.post(`accounts/${accountId}/workpermit/download`);
  }

  public studyPermitDownload(accountId: number) {
    this._transfer.post(`accounts/${accountId}/studypermit/download`);
  }

}
