import { ShiftActualState } from '@common/enums';


export const ShiftActualStates = [
  { name: 'Check In', value: ShiftActualState.Checkin, color: null },
  { name: 'Not Submitted', value: ShiftActualState.NotSubmitted, color: null },
  { name: 'As Scheduled', value: ShiftActualState.AsScheduled, color: '#4ea45b' },
  { name: 'Different', value: ShiftActualState.Different, color: '#c9b030' },
  { name: 'No Show', value: ShiftActualState.NoShow, color: '#8e77af' },
];
