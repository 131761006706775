import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class CmsData<T = any> {

  constructor(private _api: FsApi) {}

  public contentPageGet(id: number, query: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.get(
      `cms/pages/${id}`,
      query,
      {
        key: 'contentPage',
        ...config,
      },
    );
  }

  public contentPageGets(query: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.request(
      'GET',
      'cms/pages',
      query,
      {
        key: 'contentPages',
        ...config,
      },
    );
  }

  public contentPagePut(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.put(
      `cms/pages/${data.id}`,
      data,
      {
        key: 'contentPage',
        ...config,
      },
    );
  }

  public contentPagePost(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      'cms/pages',
      data,
      {
        key: 'contentPage',
        ...config,
      },
    );
  }

  public contentPageDelete(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.delete(
      `cms/pages/${data.id}`,
      data,
      {
        key: 'contentPage',
        ...config,
      },
    );
  }

  public contentPageSave(data: any): Observable<T> {
    return (data.id)
      ? this.contentPagePut(data)
      : this.contentPagePost(data);
  }


  public contentLayoutGet(id: number, query: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.get(
      `cms/layouts/${id}`,
      query,
      {
        key: 'contentLayout',
        ...config,
      },
    );
  }

  public contentLayoutGets(query: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.request(
      'GET',
      'cms/layouts',
      query,
      {
        key: 'contentLayouts',
        ...config,
      },
    );
  }

  public contentLayoutPut(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.put(
      `cms/layouts/${data.id}`,
      data,
      {
        key: 'contentLayout',
        ...config,
      },
    );
  }

  public contentLayoutPost(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      'cms/layouts',
      data,
      {
        key: 'contentLayout',
        ...config,
      },
    );
  }

  public contentLayoutDelete(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.delete(
      `cms/layouts/${data.id}`,
      data,
      {
        key: 'contentLayout',
        ...config,
      },
    );
  }

  public contentLayoutSave(data: any): Observable<T> {
    return (data.id)
      ? this.contentLayoutPut(data)
      : this.contentLayoutPost(data);
  }

  public contentPage(query: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.request(
      'GET',
      'content/page',
      query,
      {
        key: 'contentPage',
        ...config,
      },
    );
  }


  public contentWidgetSave(data): Observable<any> {
    if (data.id) {
      return this.contentWidgetPut(data);
    }

    return this.contentWidgetPost(data);
  }

  public contentWidgetGet(query): Observable<any> {
    return this._api.get('cms/widgets', query, { key: 'contentWidget' });
  }

  public contentWidgetGets(data = {}, config = {}): Observable<any> {
    return this._api.request('GET', 'cms/widgets', data, { key: 'cms/widgets', ...config });
  }

  public contentWidgetPut(contentWidget, config = {}): Observable<any> {
    return this._api.put(`cms/widgets/${contentWidget.id}`, contentWidget, { key: 'contentWidget', ...config });
  }

  public contentWidgetPost(contentWidget): Observable<any> {
    return this._api.post('cms/widgets', contentWidget, { key: 'contentWidget' });
  }

  public contentWidgetDelete(contentWidget): Observable<any> {
    return this._api.delete(`cms/widgets/${contentWidget.id}`, contentWidget, { key: 'contentWidget' });
  }

  public contentWidgetcontent(tag): Observable<any> {
    return this._api.get('cms/widget', { tag }, { key: 'content' });
  }

}
