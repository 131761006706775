import { Injectable } from '@angular/core';

import { FsApi, FsApiConfig, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuditData {

  constructor(private _fsApi: FsApi) {}

  public gets(data = {}, options = {}): Observable<any> {
    return this._fsApi.request('GET', 'audits', data, new FsApiConfig({ key: 'audits', ...options }));
  }

  public delete(data, options = {}): Observable<any> {
    return this._fsApi.request('DELETE', `audits/${data.id}`, data, new FsApiConfig({ key: 'audits', ...options }));
  }

  public put(audit, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.put(`audits/${audit.id}`, audit, { key: 'audit', ...config });
  }

  public types(objectType): Observable<any> {
    return this._fsApi.request('GET', 'audits/types', {}, new FsApiConfig({ key: 'types' }));
  }

  public actorAccountsGets(objectId: number): Observable<any> {
    return this._fsApi.request('GET', `audits/${objectId}/actoraccounts`, {}, new FsApiConfig({ key: 'accounts' }));
  }


}
