
import { makeInterceptorFactory, RequestInterceptor } from '@firestitch/api';
import { FsCookie } from '@firestitch/cookie';

import { Observable } from 'rxjs';

import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';

import { environment } from '../../../environments/environment';


export class ApiInterceptor extends RequestInterceptor {

  constructor(
    public config,
    public data,
    public cookie: FsCookie,
  ) {
    super(config, data);
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.match(/^http/)) {
      return next.handle(req.clone());
    }

    let url = environment.apiPath.concat(req.url);

    if (environment.apiDomain) {
      url = `https://${environment.apiDomain}`.concat(url);
    }

    let headers = req.headers;
    const xsrf = this.cookie.get('Token-XSRF');

    if (xsrf) {
      headers = headers.append('Token-XSRF', xsrf);
    }

    return next.handle(req.clone({ url, headers }));
  }
}

export const ApiInterceptorFactory = makeInterceptorFactory(ApiInterceptor);
