import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { FsFormModule } from '@firestitch/form';
import { FsDialogModule } from '@firestitch/dialog';

import { WorkspaceCreateComponent } from './components';


@NgModule({
  imports: [
    FormsModule,

    MatInputModule,
    MatDialogModule,
    MatButtonModule,

    FsDialogModule,
    FsFormModule,

    FlexLayoutModule,
  ],
  declarations: [
    WorkspaceCreateComponent,
  ],
  exports: [
    WorkspaceCreateComponent,
  ],
})
export class WorkspaceCreateDialogModule {}
