import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { FsDialogModule } from '@firestitch/dialog';
import { FsFormModule } from '@firestitch/form';
import { FsSkeletonModule } from '@firestitch/skeleton';

import { AutofocusModule } from '@libs/autofocus';

import {
  CreateComponent,
  InviteComponent,
  LogoComponent,
  ProgressComponent,
  ProjectComponent,
  WorkspaceComponent,
} from './components';


@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,

    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,

    FsDialogModule,
    FsFormModule,
    FsSkeletonModule,

    AutofocusModule,
  ],
  declarations: [
    CreateComponent,
    WorkspaceComponent,
    ProjectComponent,
    InviteComponent,
    LogoComponent,
    ProgressComponent,
  ],
  exports: [
    CreateComponent,
  ],
})
export class CreateModule { }
