import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { FsStore } from '@firestitch/store';

import { SidenavService } from '../../services/sidenav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {

  public account;

  constructor(
    private _store: FsStore,
    private _sidenavService: SidenavService,
    private _cdRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this._store.observe('account')
      .subscribe((store) => {
        this.account = store.value;
        this._cdRef.markForCheck();
      });
  }

  public sideNavClick(): void {
    this._sidenavService.toggle();
  }

}
