import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';

import { AclEntry } from '@app/common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class AclEntryData {

  constructor(private _api: FsApi) { }

  public create(data: AclEntry = { id: null }) {
    return data;
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }

    return this.post(data);
  }

  public get(id, data = {}): Observable<any> {
    return this._api.get(`acl/entries/${id}`, data, { key: 'aclEntry' });
  }

  public gets(data = {}, config: RequestConfig = {}): Observable<any> {
    return this._api.request('GET', 'acl/entries', data, { key: 'aclEntries', ...config });
  }

  public put(data, config: RequestConfig = {}): Observable<any> {
    return this._api.put(`acl/entries/${data.id}`, data, { key: 'aclEntry', ...config });
  }

  public post(data): Observable<any> {
    return this._api.post('acl/entries', data, { key: 'aclEntry' });
  }

  public delete(data): Observable<any> {
    return this._api.delete('acl/entries', data, { key: 'aclEntry' });
  }

  public bulk(data): Observable<any> {
    return this._api.post('acl/entries/bulk', data);
  }

  public clear(data): Observable<any> {
    return this._api.post('acl/entries/clear', data);
  }

}
