import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FsBuildModule } from '@firestitch/build';

import { CardComponent } from './components';


@NgModule({
  imports: [
    CommonModule,

    RouterModule,
    FsBuildModule,
  ],
  declarations: [
    CardComponent,
  ],
  exports: [
    CardComponent,
  ],
})
export class CardModule { }
