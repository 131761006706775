import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { AclAccess } from '@firestitch/acl';

import { AclService } from '@common/services';


@Injectable({
  providedIn: 'root',
})
export class ClientAclGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _aclService: AclService,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {

    if (this._aclService.hasPermissionClient(AclAccess.Read)) {
      return true;
    }

    return this._router.createUrlTree(['/']);
  }

}
