import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';

import { Attribute } from '@app/common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class AttributeData {

  constructor(
    private _fsApi: FsApi,
  ) { }

  public create(data: Attribute = { id: null }): Attribute {
    data.configs = data.configs || {};

    return data;
  }

  public get(id, query = {}): Observable<any> {
    return this._fsApi.get(`attributes/${id}`, query, { key: 'attribute' });
  }

  public gets(data = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', 'attributes', data, { key: 'attributes', ...config });
  }

  public put(data, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.put(`attributes/${data.id}`, data, { key: 'attribute', ...config });
  }

  public post(data, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.post('attributes', data, { key: 'attribute', ...config });
  }

  public delete(data, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.delete(`attributes/${data.id}`, data, { key: 'attribute', ...config });
  }

  public save(data, config: RequestConfig = {}): Observable<any> {
    if (data.id) {
      return this.put(data, config);
    }

    return this.post(data, config);
  }

  public order(data): Observable<any> {
    return this._fsApi.put('attributes/order', data, { key: null });
  }

  public getObjectAttributes(objectId, data, options = {}): Observable<any> {
    return this._fsApi.get(`objects/${objectId}/attributes`, data, { key: 'attributes', ...options });
  }

  public getObjectAttribute(objectId, attributeId, data): Observable<any> {
    return this._fsApi.get(`objects/${objectId}/attributes/${attributeId}`, data, { key: 'attribute' });
  }

  public assignObject(attributeId, objectId): Observable<any> {
    return this._fsApi.put(`objects/${objectId}/attributes/${attributeId}`, null, { key: 'attribute' });
  }

  public assignObjectsAttributes(attributeIds, objectIds, clearOldAttributes: boolean = false): Observable<any> {
    return this._fsApi.put('objects/attributes', { attributeIds, objectIds, clearOldAttributes }, { key: null });
  }

  public deleteObject(attributeId, objectId): Observable<any> {
    return this._fsApi.delete(`objects/${objectId}/attributes/${attributeId}`, null, { key: null });
  }

  public deleteObjectsAttributes(attributeIds, objectIds): Observable<any> {
    return this._fsApi.delete('objects/attributes', { attributeIds, objectIds }, { key: null });
  }

  public image(data, file): Observable<any> {
    return this._fsApi.post(`attributes/${data.id}/image`, { file }, { key: 'attribute' });
  }
}
