import { Injectable } from '@angular/core';

import { AttributeClasses } from '@app/common/consts';


@Injectable({
  providedIn: 'root',
})
export class AttributeService {

  public getClass(cls) {
    return AttributeClasses.find((klass) => klass.value === cls);
  }
}
