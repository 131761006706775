export enum AclPermission {
  System = 'system',
  Admin = 'admin',
  Project = 'project',
  Task = 'task',
  Doc = 'doc',
  Image = 'image',
  Environment = 'environment',
  ShiftActual = 'shiftactual',
  TempStaff = 'employeecandidate',
  OfficeStaff = 'officememployee',
  Client = 'client',
  Preferences = 'preferences',
  Shift = 'shift',
  Accounting = 'accounting',
  Timesheetsignoff = 'timesheetsignoff',
}
