import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';
import { FsTransferService } from '@firestitch/transfer';

import { Observable } from 'rxjs';

import { IWeek } from '@app/common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class WeekData {

  constructor(
    private _fsApi: FsApi,
    private _transfer: FsTransferService,
  ) { }

  public post(data, config: RequestConfig = {}): Observable<IWeek> {
    return this._fsApi.post('weeks', data, { key: 'week', ...config });
  }

  public put(data, config: RequestConfig = {}): Observable<IWeek> {
    return this._fsApi.put(`weeks/${data.id}`, data, { key: 'week', ...config });
  }

  public checkState(week: any, config: RequestConfig = {}): Observable<IWeek> {
    return this._fsApi.put('weeks/checkstate', { week }, { ...config });
  }

  public gets(query = {}, config: RequestConfig = {}): Observable<IWeek[]> {
    return this._fsApi.request('GET', 'weeks', query, { key: 'weeks', ...config });
  }

  public delete(data): Observable<IWeek> {
    return this._fsApi.delete(`weeks/${data.id}`, data, { key: 'week' });
  }

}
