import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

import { AppService } from '@common/services';


@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent implements OnInit {

  @Input() public type: 'dark' | 'light' = 'dark';
  @Input() public horizontalMaxWidth: number | string = 130;
  @Input() public verticalMaxWidth: number | string = 60;
  @Input()
  @HostBinding('class')
  public align: 'center' = 'center';

  public logoWidth;
  public logoHeight;
  public orientation;

  constructor(
    private _appService: AppService,
  ) { }

  public ngOnInit(): void {
    if (this.type === 'dark') {
      this.orientation = this._appService.settings.brandDarkLogoOrientation;
    } else if (this.type === 'light') {
      this.orientation = this._appService.settings.brandLightLogoOrientation;
    }

    if (this.orientation === 'horizontal') {
      this.logoWidth = Number.isInteger(this.horizontalMaxWidth) ? `${this.horizontalMaxWidth}px` : this.horizontalMaxWidth;
    } else {
      this.logoWidth = Number.isInteger(this.verticalMaxWidth) ? `${this.verticalMaxWidth}px` : this.verticalMaxWidth;
    }
  }

}
