import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';

import { FsApi } from '@firestitch/api';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SessionService } from './session.service';


@Injectable({
  providedIn: 'root',
})
export class SignoutService {

  constructor(
    private _router: Router,
    private _injector: Injector,
    private _sessionService: SessionService,
    private _dialogRef: MatDialog,
  ) { }

  public destroy(): Observable<any> {
    return this._injector.get(FsApi)
      .post('auth/signout')
      .pipe(
        tap(() => {
          this._sessionService.destroy();
        }),
      );
  }

  public signout(redirect = '/signin'): void {
    this.destroy()
      .subscribe(() => {
        this._dialogRef.closeAll();
        this._router.navigateByUrl(redirect);
      });
  }

}
