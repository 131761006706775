import { Injectable } from '@angular/core';

import { FsNavService, FsNavStackService } from '@firestitch/nav';

import { TitleService } from './title.service';

@Injectable({
  providedIn: 'root',
})
export class NavService extends FsNavService {

  constructor(
    private stack: FsNavStackService,
    private _titleService: TitleService,
  ) {
    super(stack);
  }

  public setTitle(
    title: string,
    supertitle?: string,
    subtitle?: string,
    permanent?: boolean,
  ): void {
    super.setTitle(title, supertitle, subtitle, permanent);
    this._titleService.setTitle((supertitle ? `${supertitle}: ` : '') + title);
    this.setComponent('image', null);
  }

  public setTitleWithImage(
    title: string,
    supertitle?: string,
    subtitle?: string,
    image?: string,
  ): void {
    this.setTitle(title, supertitle, subtitle);
    this.setComponent('image', image);
  }

}
