import { ClientState } from '@common/enums';


export const ClientStates = [
  { name: 'Active', value: ClientState.Active },
  { name: 'Inactive', value: ClientState.Inactive },
  { name: 'Do not service', value: ClientState.Donotservice },
  { name: 'Prospect', value: ClientState.Prospect },
  { name: 'Closed', value: ClientState.Closed },
  { name: 'Deleted', value: ClientState.Deleted },
];
