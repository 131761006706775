import { Injectable } from '@angular/core';

import { FsApi } from '@firestitch/api';
import { FsTransferService } from '@firestitch/transfer';

import { Observable } from 'rxjs';

import { IReport, IPayrollResponse, IPaystubResponse } from '@app/common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class ReportData {

  constructor(
    private _fsApi: FsApi,
    private _transfer: FsTransferService,
  ) { }

  public pay(query: any = {}): Observable<IReport[]> {
    return this._fsApi.get('reports/pay', query, { key: 'report' });
  }

  public payroll(query: any = {}): Observable<IPayrollResponse> {
    return this._fsApi.get('reports/payroll', query, { key: null });
  }

  public paystubs(query: any = {}): Observable<IPaystubResponse> {
    return this._fsApi.get('reports/paystubs', query, { key: null });
  }

  public shiftstatus(query: any = {}): Observable<any> {
    return this._fsApi.get('reports/shiftstatus', query, { key: 'shiftStatus' });
  }

  public shiftactualsstatus(query: any = {}): Observable<any> {
    return this._fsApi.get('reports/shiftactualsstatus', query, { key: 'shiftActualsStatus' });
  }

  public export(data: any = {}): void {
    this._transfer.post('reports/payrollexport', data);
  }

  public clients(data: any = {}): void {
    this._transfer.post('reports/clients', data);
  }

}
