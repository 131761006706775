import {
  AttributeColor,
  AttributeImage,
  AttributeOrder,
  FsAttributeConfig,
} from '@firestitch/attribute';
import { list } from '@firestitch/common';
import { FsMessage } from '@firestitch/message';
import { FsPrompt } from '@firestitch/prompt';
import { FlatItemNode } from '@firestitch/tree';

import { Observable, concat, of } from 'rxjs';
import { concatAll, map, switchMap } from 'rxjs/operators';

import { AttributeData, AttributeService } from '../';

import { AttributeClass } from './../../common/enums/attribute-class.enum';

export function attributeConfigFactory(
  attributeData: AttributeData,
  attributeService: AttributeService,
  prompt: FsPrompt,
  message: FsMessage,
): FsAttributeConfig {

  const tagClass = attributeService.getClass(AttributeClass.Tag);
  const timeEntryTagClass = attributeService.getClass(AttributeClass.TimeEntryTag);

  const config = {
    configs: [
      {
        class: tagClass.value,
        name: tagClass.name,
        pluralName: tagClass.pluralName,
        image: AttributeImage.Disabled,
        backgroundColor: AttributeColor.Enabled,
        color: AttributeColor.Enabled,
        order: AttributeOrder.Custom,
      },
      {
        class: timeEntryTagClass.value,
        name: timeEntryTagClass.name,
        pluralName: timeEntryTagClass.pluralName,
        image: AttributeImage.Disabled,
        backgroundColor: AttributeColor.Enabled,
        color: AttributeColor.Enabled,
        order: AttributeOrder.Custom,
      },
    ],
    mapping: {
      id: 'id',
      name: 'name',
      image: 'image.tiny',
      backgroundColor: 'backgroundColor',
      configs: 'configs',
      childAttributes: 'childAttributes',
      parentAttribute: 'parentAttribute',
    },
    getAttributeTree: (e) => {
      return attributeData.gets({
        class: e.class,
        childAttributes: true,
      }).pipe(
        switchMap((data) => {
          return of({ attributes: data });
        }),
      );
    },
    reorderAttributeTree: (event: any) => {

      const requests: any = [];

      if (event.toParent) {
        const moveRequest = attributeData.put({
          ...event.attribute,
          parentAttributeId: event.toParent,
        });

        const orderRequest = attributeData.order({
          class: event.class,
          parentAttributeId: event.toParent,
          attributeIds: event.childIds,
        });

        requests.push(moveRequest, orderRequest);
      } else {
        const orderRequest = attributeData.order({
          class: event.class,
          attributeIds: event.parentIds,
        });

        requests.push(orderRequest);
      }

      return concat(requests).pipe(concatAll());
    },
    canDropTreeAttribute: (
      node?: FlatItemNode,
      fromParent?: FlatItemNode,
      toParent?: FlatItemNode,
      dropPosition?: any,
      prevElement?: FlatItemNode,
      nextElement?: FlatItemNode,
    ) => {

      // Sorting Rule
      const prevElSortCoimplied = prevElement && prevElement.data.name < node.data.name || !prevElement;
      const nextElSortCoimplied = nextElement && node.data.name < nextElement.data.name || !nextElement;
      const compliedWithSort = prevElSortCoimplied && nextElSortCoimplied;

      return (!fromParent && !toParent) ||
        (fromParent && toParent && fromParent.data.class === toParent.data.class && compliedWithSort);
    },
    sortByAttributeTree: (data, parent) => {
      if (!parent) {
        return data;
      }

      return data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (b.name < b.name) {
          return 1;
        }

        return 0;
      });
    },
    saveAttribute: (e, draft = false) => {

      const state = draft ? 'draft' : 'active';

      const attribute = e.attribute;
      attribute.class = e.class;
      attribute.state = state;

      if (e.data && e.data.projectId) {
        attribute.projectId = e.data.projectId;
      }

      return attributeData.save(attribute, { key: null });
    },
    saveAttributeImage: (e) => {
      if (e.attribute.id) {
        return attributeData
          .image({ id: e.attribute.id }, e.file.file);
      }
      e.attribute.state = 'draft';

      return config.saveAttribute(e, !e.attribute.id)
        .pipe(
          switchMap((response: any) => {
            const attribute = response.attribute;

            return attributeData
              .image({ id: attribute.id }, e.file.file);
          }),
        );

    },
    getAttributes: (e) => {
      const query = {
        ...e.query,
        class: e.class,
      };

      if (e.keyword) {
        query.keyword = e.keyword;
      }

      if (e.data && e.data.childAttributes) {
        query.childAttributes = !!e.data.childAttributes;
      }

      if (e.data && e.data.excludeEmpty) {
        query.excludeEmptyParentAttributes = !!e.data.excludeEmpty;
      }

      if (e.data && e.data.parentAttributes) {
        query.parentAttributes = !!e.data.parentAttributes;
      }

      if (e.data && e.data.projectId) {
        query.projectId = e.data.projectId;
      }

      return attributeData.gets(query,
        {
          key: null,
        }).pipe(
          map((response) => {
            return { data: response.attributes, paging: response.paging };
          }),
        );
    },
    getSelectedAttributes: (e) => {

      const query = {
        ...e.query,
        class: e.class,
        parentAttributes: !!e.parentClass,
      };

      if (e.data && e.data.childAttributes) {
        query.childAttributes = !!e.data.childAttributes;
      }

      if (e.data && e.data.parentAttributes) {
        query.parentAttributes = !!e.data.parentAttributes;
      }

      if (e.data && e.data.projectId) {
        query.projectId = e.data.projectId;
      }

      return attributeData.getObjectAttributes(e.data.objectId, query,
        {
          key: null,
        }).pipe(
          map((response) => {
            return { data: response.attributes, paging: response.paging };
          }),
        );
    },
    reorderAttributes: (e) => {

      return attributeData.order({
        class: e.class,
        attributeIds: list(e.attributes, 'id'),
      });
    },
    attributeSelectionChanged: (e) => {
      if (e.data.disableAutoSave) {
        return of({ attribute: e.attribute });
      }

      const attribute = e.value;

      return new Observable((observer) => {

        if (e.selected) {
          attributeData.assignObject(attribute.id, e.data.objectId)
            .subscribe((response) => {
              message.success('Saved Changes');
              observer.next({ attribute });
              observer.complete();
            });

          return;
        }

        attributeData.deleteObject(attribute.id, e.data.objectId)
          .subscribe((response) => {
            message.success('Saved Changes');
            observer.next({ attribute });
            observer.complete();
          });
      });
    },
    deleteAttribute: (e) => {

      return attributeData.delete({ id: e.id }, { key: null });
    },
    compareAttributes: (o1, o2) => {
      return o1 && o2 && o1.id === o2.id;
    },
    deleteConfirmation: (event) => {
      const klass = attributeService.getClass(event.class);
      const templateName = `Are you sure you would like to delete this ${klass ? klass.name : 'Undefined'}?`;

      return prompt.confirm({
        title: 'Confirm',
        template: templateName,
      });
    },
  };

  return config;
}
