export enum ObjectClass {
  File = 'file',
  Tag = 'tag',
  Type = 'type',
  Status = 'status',
  Address = 'address',
  Comment = 'comment',
  Shift = 'shift',
  FieldStaff = 'fieldstaff',
  OfficeStaff = 'officestaff',
  Candidate = 'candidate',
  Contact = 'contact',
  OutsourcedStaff = 'outsourcedstaff',
  Account = 'account',
  Client = 'client',
  ClientLocation = 'client_location',
  ClientAccount = 'client_account',
  Office = 'office',
  ShiftActual = 'shift_actual',
  StaffRequest = 'staff_request',
  Week = 'week',
  Adjustment = 'adjustment',
  Payer = 'payer',
  Preference = 'preference',
  EmployeeCertification = 'employee_cert',

  //leaving these here to prevent undefined errors
  Doc = 'doc',
  Task = 'task',
  Project = 'project',
  AssetImage = 'asset_image',
  AssetFile = 'asset_file',
  AssetVideo = 'asset_video',
  Component = 'component',
  DataObjectTable = 'data_object_table',
  DataObjectColumn = 'data_object_column',
  Plan = 'plan',
  Scenario = 'scenario',
  Data = 'data',
}
