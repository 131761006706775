import { Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';

import { FsApi, RequestConfig } from '@firestitch/api';
import { FsCookie } from '@firestitch/cookie';
import { FsMessage } from '@firestitch/message';
import { FsStore } from '@firestitch/store';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';


import { SigninService } from '../../../../../common/services/signin.service';

import { SessionService } from './session.service';


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public loggedInAccount$: Observable<any> = this._store.observe('account')
    .pipe(
      // Return session account only if it is actual
      // Return null if session was expired. It allow us to control event when user was signed out
      map((store) => {
        if (!this._signinService.signedIn()) {
          return null;
        }

        return store;
      }),
    );

  constructor(
    @Optional() private _api: FsApi,
    private _router: Router,
    private _message: FsMessage,
    private _store: FsStore,
    private _signinService: SigninService,
    private _sessionService: SessionService,
    private _cookie: FsCookie,
  ) { }

  public signin(email: string, password: string, google2faCode: number = null, config = {}): Observable<any> {
    return this.processResponse(this._api.post('auth/signin', { email, password, google2faCode }, config));
  }

  public siginFacebook(accessToken: string, config = {}): Observable<any> {
    const data: any = { accessToken };

    return this.processResponse(this._api.post('auth/facebook', data, config));
  }

  public signinGoogle(idToken: string, accessToken: string, config = {}): Observable<any> {
    const data: any = { idToken, accessToken };

    return this.processResponse(this._api.post('auth/google', data, config));
  }

  public signinVerify(code: any, trust: boolean, config: RequestConfig = {}): Observable<void> {
    return this.processResponse(this._api.post('auth/signin/verify', {
      code,
      trust,
    }, config));
  }

  public processResponse(observable: Observable<any>): Observable<any> {
    return observable
      .pipe(
        tap((response) => {
          this._sessionService.set(response);
        }),
      );
  }

  public reloadPermissions(): Observable<any> {
    return this._api.get('auth/permissions')
      .pipe(
        tap((permissions) => {
          this._sessionService.permissions(permissions);
        }),
      );
  }

  // public processSignin(response, options: { newAccount?: boolean; redirect?: string } = {}): void {
  //   this._sessionService.set(response);
  //   this._signinService.processRedirect(options.redirect);

  //   if (options.newAccount) {
  //     this._message.success(`Welcome ${response.account.firstName}`);
  //   } else if (!response.account.passwordChange) {
  //     this._message.success(`Welcome back ${response.account.firstName}`);
  //   }
  // }

  public destroy(): Observable<any> {
    return this._api.post('auth/signout')
      .pipe(
        tap(() => {
          this._cookie.deleteAll();
          this._sessionService.destroy();
        }),
      );
  }

  public verify(password): Observable<any> {
    return this._api.post('auth/verify', { password });
  }

  public signout(redirect = '/signin'): void {
    this.destroy()
      .subscribe(() => {
        this._router.navigateByUrl(redirect);
      });
  }

  public impersonate(accountId: number): Observable<any> {
    return this.processResponse(this._api.post('auth/impersonate', { accountId }));
  }

}
