import { Injectable } from '@angular/core';

import { FsApi } from '@firestitch/api';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class SigninData {

  constructor(private _api: FsApi) { }

  public gets(data: any = {}, config = {}): Observable<any> {
    return this._api.get('signins', data, { key: 'signins', ...config });
  }
}
