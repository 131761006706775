import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class InviteData {

  constructor(private _api: FsApi) { }

  public gets(data: any = {}, config: RequestConfig = {}): Observable<any> {
    return this._api.get('invites', data, { key: 'accountRequest', ...config });
  }

  public get(data, config: RequestConfig = {}): Observable<any> {
    return this._api.request('GET', 'invites', data, { key: 'accountRequests', ...config });
  }

  public post(data): Observable<any> {
    return this._api.post('invites', data, { key: 'accountRequest' });
  }

  public resend(accountInviteId: number, environmentId: number = null): Observable<any> {
    return this._api.post(`invites/${accountInviteId}/resend`, { environmentId }, { key: 'accountRequest' });
  }

  public delete(data): Observable<any> {
    return this._api.delete(`invites/${data.id}`, data, { key: 'accountRequest' });
  }
}
