<form #form fsForm [submit]="save" [dirtySubmitButton]="false" *fsSkeletonForm="environmentCreate">
  <mat-card-content>
    <div fxLayout="column" fxLayoutAlign="start center" class="full-width">
      <h1>Who else should we invite?</h1>

      <div fxLayout="column" class="full-width">
        <div fxLayout="row" fxLayoutGap="5px" *ngFor="let item of environmentCreate.members; let index = index">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="Email"
              [(ngModel)]="item.email"
              name="email_{{ item.guid }}"
              fsFormEmail="true"
            >
          </mat-form-field>
          <mat-form-field>
            <mat-select
              [(ngModel)]="item.aclRoleReference"
              name="acl_role_id_{{ item.guid }}"
              placeholder="Role">
                <mat-option
                  *ngFor="let aclRoleReference of aclRoleReferences"
                  [value]="aclRoleReference.value">
                    {{ aclRoleReference.name }}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button type="submit" mat-raised-button color="primary" class="full-width">Continue</button>
  </mat-card-actions>
</form>
