import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class TrustedDeviceData {

  constructor(private _api: FsApi) { }

  public get(id, query = {}, config: RequestConfig = {}): Observable<any> {
    return this._api.get(`trusteddevices/${id}`, query, { key: 'trustedDevice', ...config });
  }

  public gets(data = {}, config: RequestConfig = {}): Observable<any> {
    return this._api.request('GET', 'trusteddevices', data, { key: 'trustedDevices', ...config });
  }

  public delete(data, config: RequestConfig = {}): Observable<any> {
    return this._api.delete(`trusteddevices/${data.id}`, data, { key: 'trustedDevice', ...config });
  }

  public signout(data, config: RequestConfig = {}): Observable<any> {
    return this._api.post(`trusteddevices/${data.id}/signout`, data, { key: 'trustedDevice', ...config });
  }
}
