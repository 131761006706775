
import { Injectable } from '@angular/core';

import { of, Observable, forkJoin } from 'rxjs';

import { MentionData } from '../data';

@Injectable({
  providedIn: 'root',
})
export class MentionService {

  constructor(
    private _mentionData: MentionData,
  ) {}

  public process(objectId: number, html: string): Observable<any> {
    const div = document.createElement('div');
    div.innerHTML = html;

    return forkJoin(
      this._processAccountMentions(objectId, div),
      this._processObjectMentions(objectId, div),
    );
  }

  private _processAccountMentions(objectId: number, div: HTMLElement): Observable<any> {
    const mentions = Array.from(div.querySelectorAll('[data-mention="account"]'))
      .map((el: Element) => {
        return {
          reference: el.getAttribute('data-ref'),
          accountId: el.getAttribute('data-account-id'),
          type: el.getAttribute('data-type'),
          message: (el.nextSibling || {}).nodeValue,
        };
      });

    return mentions.length ? this._mentionData.accountsPost(objectId, { mentions }) : of(true);
  }

  private _processObjectMentions(objectId: number, div: HTMLElement): Observable<any> {
    const mentions = Array.from(div.querySelectorAll('[data-mention="object"]'))
      .map((el: Element) => {
        return {
          reference: el.getAttribute('data-ref'),
          objectId: el.getAttribute('data-object-id'),
        };
      });

    return mentions.length ? this._mentionData.objectsPost(objectId, { mentions }) : of(true);
  }

}
