import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class AclRoleData {

  constructor(private _api: FsApi) { }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }

    return this.post(data);
  }

  public permissions(): Observable<any> {
    return this._api.get('acl/config', {}, { key: 'permissions' });
  }

  public levels(): Observable<any> {
    return this._api.get('acl/config', {}, { key: 'levels' });
  }

  public get(id, data = {}): Observable<any> {
    return this._api.get(`acl/roles/${id}`, data, { key: 'aclRole' });
  }

  public gets(data = {}, config: RequestConfig = {}): Observable<any> {
    return this._api.request('GET', 'acl/roles', data, { key: 'aclRoles', ...config });
  }

  public put(data, config: RequestConfig = {}): Observable<any> {
    return this._api.put(`acl/roles/${data.id}`, data, { key: 'aclRole', ...config });
  }

  public post(data): Observable<any> {
    return this._api.post('acl/roles', data, { key: 'aclRole' });
  }

  public delete(data): Observable<any> {
    return this._api.delete(`acl/roles/${data.id}`, data, { key: 'aclRole' });
  }
}
