import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { guid } from '@firestitch/common';
import { FsMessage } from '@firestitch/message';

import { Observable, Subject, forkJoin, of, zip } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Account } from '@common/interfaces';

import { AclRoleLevel, AclRoleReferences } from '@app/common';
import { AclRoleReference } from '@app/common/enums';
import { AclRole, Environment, Project } from '@app/common/interfaces';
import { AclRoleData, EnvironmentData, InviteData, ProjectData } from '@app/core/data';
import { AuthService, SessionService } from '@app/core/services';

import { EnvironmentCreate } from '../../interfaces';


@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteComponent implements OnInit, OnDestroy {

  @Input() public environmentCreate: EnvironmentCreate = null;

  @Output() public completed = new EventEmitter();

  public aclRoleReferences = AclRoleReferences;

  public account: Account = null;

  private _destroy$ = new Subject();

  constructor(
    private _cd: ChangeDetectorRef,
    private _router: Router,
    private _environmentData: EnvironmentData,
    private _projectData: ProjectData,
    private _sessionService: SessionService,
    private _authService: AuthService,
    private _inviteData: InviteData,
    private _fsMessage: FsMessage,
    private _aclRoleData: AclRoleData,
  ) { }

  public ngOnInit(): void {
    this._sessionService.account$
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe((account) => this.account = account);

    this.memberAdd(3);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public focus(index: number): void {
    if (index === (this.environmentCreate.members.length - 1)) {
      this.memberAdd();
    }
  }

  public memberAdd(count: number = 1): void {
    for (let i = 0; i < count; i++) {
      this.environmentCreate.members.push({
        email: null,
        aclRoleReference: AclRoleReference.EnvironmentAdmin,
        guid: guid(),
      });
    }

    this._cd.detectChanges();
  }

  public save = () => {
    return this._environmentData.post({ name: this.environmentCreate.workspaceName })
      .pipe(
        switchMap((environment: Environment) => {
          return zip(
            this._authService.reloadPermissions(),
            this._environmentData.switch(this.account.id, environment.id),
          )
            .pipe(
              map(() => {
                this._sessionService.environment(environment);

                return environment;
              }),
              takeUntil(this._destroy$),
            );
        }),
        switchMap((environment: Environment) => {
          return this._projectData.post({ name: this.environmentCreate.projectName })
            .pipe(
              takeUntil(this._destroy$),
            )
            .pipe(
              map((project: Project) => [environment, project]),
            );
        }),
        switchMap(([environment, project]) => this._saveInvites(environment, project as Project)),
      )
      .pipe(
        tap((project: Project) => {
          this._fsMessage.success('Your workspace is ready');
          this._router.navigate(['/projects']);
          this.completed.emit();
        }),
        takeUntil(this._destroy$),
      );
  };

  private _saveInvites(environment: Environment, project: Project): Observable<Project> {
    return this._aclRoleData.gets({
      environmentId: environment.id,
      reference: AclRoleReferences.map((item) => {
        return item.value;
      }).join(','),
    })
      .pipe(
        switchMap((aclRoles) => {
          const queries$ = [];
          this.environmentCreate.members
            .filter((item) => item.email && item.aclRoleReference)
            .forEach((member) => {
              const selectedRole = aclRoles
                .find((role: AclRole) => {
                  return role.reference === member.aclRoleReference;
                });

              const objectId = selectedRole.level === AclRoleLevel.Environment ?
                environment.id : project.id;

              queries$.push(this._inviteData.post({
                email: member.email,
                environmentId: environment.id,
                level: selectedRole.level,
                objects: [
                  {
                    aclRoleIds: [selectedRole.id],
                    objectId,
                  },
                ],
              }));
            });

          if (!queries$.length) {
            queries$.push(of(true));
          }

          return forkJoin(...queries$)
            .pipe(
              map(() => project),
              takeUntil(this._destroy$),
            );
        }),
      );
  }

}
