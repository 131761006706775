import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';

import { Portal } from '@app/common/enums';


@Injectable({
  providedIn: 'root',
})
export class PortalService {

  private _portal$ = new BehaviorSubject<Portal>(null);

  public get portal$(): Observable<Portal> {
    return this._portal$
      .asObservable();
  }

  public get isPortalAdmin(): boolean {
    return this.portal === Portal.Admin;
  }

  public get isPortalTempStaff(): boolean {
    return this.portal === Portal.TempStaff;
  }

  public get isPortalContact(): boolean {
    return this.portal === Portal.Contact;
  }

  public get portal(): Portal {
    return this._portal$.getValue();
  }

  constructor(
    private _router: Router,
  ) {
    this.setPortal(this._getPortal());

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => window.location.pathname),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.setPortal(this._getPortal());
      });
  }

  public setPortal(value: Portal): void {
    this._portal$.next(value);
  }

  private _getPortal(): Portal {
    if (this._router.url.match(/^\/tempstaff\//) || this._router.url.match(/^\/staff\//)) {
      return Portal.TempStaff;
    }

    if (this._router.url.match(/^\/contact\//)) {
      return Portal.Contact;
    }

    return Portal.Admin;
  }

}
