import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { FsSkeletonModule } from '@firestitch/skeleton';
import { FsDrawerModule } from '@firestitch/drawer';
import { FsFormModule } from '@firestitch/form';
import { FsLabelModule } from '@firestitch/label';
import { FsDialogModule } from '@firestitch/dialog';

// import { SideDrawerHeaderModule } from '@libs/drawers/drawer/modules/side-drawer-header';

import { ShiftAssignmentComponent } from './components';

// TODO: for now it will be dialog. Drawers within app on review
@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,

    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,

    FsDrawerModule,
    FsFormModule,
    FormsModule,
    FsLabelModule,
    FsDialogModule,
    FsSkeletonModule,

    // SideDrawerHeaderModule,
  ],
  declarations: [
    ShiftAssignmentComponent,
  ],
  exports: [
    ShiftAssignmentComponent,
  ],
})
export class ShiftAssignmentDrawerModule { }

