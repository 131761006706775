import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';

import { FsFormModule } from '@firestitch/form';
import { FsDialogModule } from '@firestitch/dialog';


import { StatusMenuModule } from '@libs/status-menu';

import { ProjectCreateComponent } from './components';


@NgModule({
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    FormsModule,

    FsFormModule,
    FsDialogModule,

    StatusMenuModule,
  ],
  declarations: [ProjectCreateComponent],
  exports: [ProjectCreateComponent],
})
export class ProjectCreateModule { }
