import { Injectable } from '@angular/core';

import { FsApi } from '@firestitch/api';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class ActivationData {

  constructor(private _api: FsApi) { }

  public activations(data: any = {}): Observable<any> {
    return this._api.post('activations', data, { key: 'accountRequest' });
  }
}
