import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class EnvironmentData {

  constructor(private _api: FsApi) { }

  public save(data, config: RequestConfig = {}): Observable<any> {
    if (data.id) {
      return this.put(data, config);
    }

    return this.post(data, config);
  }

  public get(environmentId, data: any = {}): Observable<any> {
    return this._api.get(`environments/${environmentId}`, data, { key: 'environment' });
  }

  public gets(data: any = {}, config: RequestConfig = {}): Observable<any> {
    return this._api.request('GET', 'environments', data, { key: 'environments', ...config });
  }

  public put(environment, config: RequestConfig = {}): Observable<any> {
    return this._api.put(`environments/${environment.id}`, environment, { key: 'environment', ...config });
  }

  public post(environment, config: RequestConfig = {}): Observable<any> {
    return this._api.post('environments', environment, { key: 'environment', ...config });
  }

  public delete(environment): Observable<any> {
    return this._api.delete(`environments/${environment.id}`, environment, { key: 'environment' });
  }

  public switch(accountId: number, environmentId: number): Observable<any> {
    return this._api.put(`accounts/${accountId}/environments/${environmentId}/switch`, null, { key: 'environment' });
  }

  public accountsDelete(environmentId: number, accountId, data: any = {}, config: RequestConfig = {}): Observable<any> {
    return this._api.delete(`environments/${environmentId}/accounts/${accountId}`,
      data, { key: 'objectAccount', ...config });
  }

  public accountsPost(environmentId: number, accountId, data: any = {}, config: RequestConfig = {}): Observable<any> {
    return this._api.post(`environments/${environmentId}/accounts/${accountId}`,
      data, { key: '', ...config });
  }
}
