import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TitleService {

  private _titles: { [key: number]: string } = {};

  constructor(
    private _title: Title) {
  }

  public setTitle(
    title: string,
    index: number = 0,
  ): void {
    this._titles[index] = title;

    if (index >= this._getCurrentMaxIndex()) {
      this._title.setTitle(title);
    }
  }

  public clearTitle(
    index: number = 0,
  ): void {
    delete this._titles[index];
    this._title.setTitle(this._titles[this._getCurrentMaxIndex()]);
  }

  private _getCurrentMaxIndex(): number {
    return 0;

    return Object.keys(this._titles)
      .map((item) => parseInt(item))
      .reduce((a, b) => a > b ? a : b);
  }

}
