import { Injectable } from '@angular/core';

import { FsApi } from '@firestitch/api';
import { FsTransferService } from '@firestitch/transfer';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class MessageQueueData {

  constructor(
    private _transfer: FsTransferService,
    private _fsApi: FsApi,
  ) { }

  public create(data) {
    return data;
  }

  public get(id, query = {}): Observable<any> {
    return this._fsApi.get(`messages/queues/${id}`, query, { key: 'messageQueue' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', 'messages/queues', data, { key: 'messageQueues', ...config });
  }

  public put(data, config = {}): Observable<any> {
    return this._fsApi.put(`messages/queues/${data.id}`, data, { ...config, key: 'messageQueues' });
  }

  public resend(data): Observable<any> {
    return this._fsApi.post(`messages/queues/${data.id}/resend`, {}, { key: 'messageQueue' });
  }

  public forward(data, value): Observable<any> {
    return this._fsApi.post(`messages/queues/${data.id}/forward`, { recipient: value }, { key: 'messageQueue' });
  }

  public logsGets(data, query = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', `messages/queues/${data.id}/logs`, query, { key: 'messageLogs', ...config });
  }

  public attachmentsGets(data, query = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', `messages/queues/${data.id}/attachments`, query, { key: 'messageQueueAttachments', ...config });
  }

  public bulk(action, messageQueueIds): Observable<any> {
    return this._fsApi.post('messages/queues/bulk', { action, messageQueueIds }, { key: 'messageQueues' });
  }

  public eventsGets(data, query = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', `messages/queues/${data.id}/events`, query, { key: 'messageQueueEvents', ...config });
  }

  public downloadAttachment(messageQueueAttachment, messageQueue) {
    this._transfer.post(`messages/queues/${messageQueue.id}/attachments/${messageQueueAttachment.id}/download`);
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
  }
}
