import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';

import { IIdentification } from '@common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class IdentificationData<T = any> {

  constructor(private _api: FsApi) { }

  public create(data: IIdentification = { id: null }): IIdentification {
    return data;
  }

  public get(id: number, query: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.get(
      `identifications/${id}`,
      query,
      {
        key: 'identification',
        ...config,
      },
    );
  }

  public gets(query: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.request(
      'GET',
      'identifications',
      query,
      {
        key: 'identifications',
        ...config,
      },
    );
  }

  public put(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.put(
      `identifications/${data.id}`,
      data,
      {
        key: 'identification',
        ...config,
      },
    );
  }

  public post(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      'identifications',
      data,
      {
        key: 'identification',
        ...config,
      },
    );
  }

  public delete(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.delete(
      `identifications/${data.id}`,
      data,
      {
        key: 'identification',
        ...config,
      },
    );
  }

  public save(data: any): Observable<T> {
    return (data.id)
      ? this.put(data)
      : this.post(data);
  }

}
