import { Component, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';

import { MENU_DATA, SidenavMenuRef } from '@firestitch/sidenav';

import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { SidenavService } from '@libs/layouts/side-nav/services';

import { CreateComponent } from '@app/environment/modules/create/components/create';

import { EnvironmentSwitchComponent } from '../environment-switch/environment-switch.component';


@Component({
  templateUrl: './environment-menu.component.html',
  styleUrls: ['./environment-menu.component.scss'],
})
export class EnvironmentMenuComponent implements OnDestroy {

  public environment;
  public hasSettingsAccess;

  private _destroy$ = new Subject();

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private _sidenavService: SidenavService,
    public menuRef: SidenavMenuRef<EnvironmentMenuComponent>,
    @Inject(MENU_DATA) public data,
    private _cd: ChangeDetectorRef,
  ) {
    this.environment = data.environment;
    this.hasSettingsAccess = data.hasSettingsAccess;
  }

  public close(): void {
    this.menuRef.close();
  }

  public create(): void {
    this.close();

    this.dialog.open(CreateComponent, {
      data: { autofocus: false },
    });
  }

  public switch(): void {
    this.close();
    this.dialog.open(EnvironmentSwitchComponent, {
      data: {},
    })
      .afterClosed()
      .pipe(
        filter((response) => !!response),
        takeUntil(this._destroy$),
      )
      .subscribe(() => { });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
