import { Injectable, OnDestroy } from '@angular/core';

import { guid } from '@firestitch/common';

import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class WindowMessageService implements OnDestroy {

  public event$ = new Subject <{ type: any; data: any; guid: string }>();

  private _messageKey = 'fs-window-message';
  private _window = window;
  private _guid = guid();

  constructor() { }

  public init(): void {
    this._window.addEventListener('storage', (event: StorageEvent) => {
      if (event.key === this._messageKey) {
        const eventData = JSON.parse(event.newValue);
        if (this._guid !== eventData.guid) {
          this.event$.next(eventData);
        }
      }
    });
  }

  public broadcast(type: string, data?: any): void {
    this._window
      .localStorage
      .setItem(this._messageKey, JSON.stringify({ type, data, guid: this._guid }));
  }

  public ngOnDestroy(): void {
    this.event$.complete();
  }

}
