import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';

import { ICertification } from '@app/common/interfaces';


@Injectable({
  providedIn: 'root',
})
export class CertificationData {

  constructor(private _fsApi: FsApi) { }

  public create(data: ICertification = { id: null }): ICertification {
    data.certificationSkills = data.certificationSkills || [];
    data.skillsDivisionsFormatted = [];
    data.certificationSkills
      .forEach((ceritificationSkill) => {
        const skillName = ceritificationSkill.skill.name;
        const divisionsName = ceritificationSkill.skill.divisions
          .map((item) => item.name);
        data.skillsDivisionsFormatted.push(
          [
            skillName,
            divisionsName.join(', '),
          ].join(': '),
        );
      });
    data.skillsDivisionsFormatted.sort();

    return data;
  }

  public get(id: number, query: any = {}): Observable<any> {
    return this._fsApi.get(`certifications/${id}`, query, { key: 'certification' });
  }

  public gets(query = {}, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.request('GET', 'certifications', query, { key: 'certifications', ...config });
  }

  public put(data, config: RequestConfig = {}): Observable<any> {
    return this._fsApi.put(`certifications/${data.id}`, data, { key: 'certification', ...config });
  }

  public post(data): Observable<any> {
    return this._fsApi.post('certifications', data, { key: 'certification' });
  }

  public putCertifications(certificationId: number, data): Observable<any> {
    return this._fsApi.put(`certifications/${certificationId}/skills`, data, { key: 'certification' });
  }

  public delete(data): Observable<any> {
    return this._fsApi.delete(`certifications/${data.id}`, data, { key: 'certification' });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }

    return this.post(data);
  }

}
