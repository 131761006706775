import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EnvironmentCreate } from '../../interfaces';


@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceComponent implements OnInit {

  @Input() public environmentCreate: EnvironmentCreate = null;

  @Output() public environmentCreateChange = new EventEmitter<EnvironmentCreate>();

  constructor() { }

  public ngOnInit(): void {
  }

  public save = () => {
    return of(this.environmentCreate)
      .pipe(
        tap((response) => this.environmentCreateChange.emit(response)),
      );
  };

}
