<fs-dialog>
  <div mat-dialog-title>Switch Workspace</div>
  <mat-dialog-content>
    <div fxLayout="column" class="environment-items">
      <div class="environment-item" *ngFor="let environment of environments" (click)="switch(environment)">
        <div fxLayout="row" fxLayoutGap="10px">
          <fs-badge shape="circle" size="30" image="{{ environment.avatar.tiny }}"></fs-badge>
          <div fxLayout="column" fxLayoutAlign="center start">
            <span>{{ environment.name }}</span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
              <fs-badge *ngFor="let project of environment.projects" size="25" [image]="project.avatar?.tiny"></fs-badge>
              <span *ngIf="environment.projects.length > projectLimit">+&nbsp;{{ environment.projects.length - projectLimit }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button [mat-dialog-close]="null" type="button">Done</button>
  </mat-dialog-actions>
</fs-dialog>
