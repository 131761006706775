<form #form fsForm [submit]="save" *fsSkeletonForm="environmentCreate">
  <mat-card-content>
    <div fxLayout="column" fxLayoutAlign="start center">
      <h1>What is the first project your team will work on?</h1>
      <mat-form-field class="full-width">
        <input matInput placeholder="Project Name" [(ngModel)]="environmentCreate.projectName" fsFormRequired name="project_name">
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button type="submit" mat-raised-button color="primary" class="full-width">Continue</button>
  </mat-card-actions>
</form>
