import { FsContentConfig, FsContentPage } from '@firestitch/content';

import { CmsData } from '@common/data';


export function contentConfigFactory(
  cmsData: CmsData,
): FsContentConfig {
  return {
    loadContentPages: (query) => {
      return cmsData.contentPageGets(query, { key: null });
    },
    saveContentPage: (contentPage: FsContentPage) => {
      return cmsData.contentPageSave(contentPage);
    },
    deleteContentPage: (contentPage: FsContentPage) => {
      return cmsData.contentPageDelete(contentPage);
    },
    loadContentLayouts: (query) => {
      return cmsData.contentLayoutGets(query);
    },
    saveContentLayout: (contentLayout) => {
      return cmsData.contentLayoutSave(contentLayout);
    },
    deleteContentLayout: (contentPage: FsContentPage) => {
      return cmsData.contentLayoutDelete(contentPage);
    },
    loadContent: (path: string) => {
      return cmsData.contentPage({ path });
    },
  };
}
