import { Injectable } from '@angular/core';

import { FsApi, RequestConfig } from '@firestitch/api';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class VerificationMethodData<T = any> {

  constructor(private _api: FsApi) { }

  public get(id: number, query: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.get(
      `verificationmethods/${id}`,
      query,
      {
        key: 'verificationMethod',
        ...config,
      },
    );
  }

  public gets(query: any = {}, config: RequestConfig = {}): Observable<T> {
    return this._api.request(
      'GET',
      'verifications/methods',
      query,
      {
        key: 'verificationMethods',
        ...config,
      },
    );
  }

  public post(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      'verifications/methods',
      data,
      {
        key: 'verificationMethod',
        ...config,
      },
    );
  }

  public default(verificationMethod, config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      `verifications/methods/${verificationMethod.id}/default`,
      {},
      {
        key: 'verificationMethod',
        ...config,
      },
    );
  }

  public verify(code: any, trustDevice: boolean, config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      'verifications/methods/verify',
      { code, trustDevice },
      {
        key: 'verificationMethod',
        ...config,
      },
    );
  }

  public resend(config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      'verifications/methods/resend',
      {},
      {
        key: '',
        ...config,
      },
    );
  }

  public account(config: RequestConfig = {}): Observable<T> {
    return this._api.post(
      'verifications/methods/account',
      {},
      {
        key: '',
        ...config,
      },
    );
  }

  public delete(data: any, config: RequestConfig = {}): Observable<T> {
    return this._api.delete(
      `verifications/methods/${data.id}`,
      data,
      {
        key: 'verificationMethod',
        ...config,
      },
    );
  }

}
